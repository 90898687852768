import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import config from "../config";
import Loader from "../components/Loader";
import logo from "../assets/logo-dark.png";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { fetchApiOld } from "../api";

const ArticleView = () => {
  const location = useLocation();
  // @ts-ignore
  const { articleId } = useParams();
  const [article, setArticle] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // @ts-ignore
    if (location.state && location.state.baseArticle) {
      // @ts-ignore
      setArticle(location.state.baseArticle);
      setIsLoading(false);
    } else {
      fetchApiOld(config.oldApi.viewArticle(articleId ?? ""))
        .then((response) => {
          const curArticle = response.data;
          curArticle["image_url"] =
            "https://v3.kalm-app.com/files/articles/" + curArticle["file"];
          setArticle(curArticle);
        })
        .catch(() => {
          alert("failed to load article");
        })
        .finally(() => setIsLoading(false));
    }
  }, [articleId]);

  return (
    <div className="mx-auto lg:max-w-6xl">
      {article && !isLoading ? (
        <div className="flex flex-col items-center py-5 md:py-10 ">
          <div>
            <LazyLoadImage
              placeholderSrc={logo}
              alt="article-img"
              effect="blur"
              src={config.resourceApi.baseFileApiUrl + article.file}
            />
          </div>
          <div className="flex justify-center py-5 md:py-10">
            <p className="text-2xl md:text-4xl text-primaryOrange font-bold text-center">
              {article.name}
            </p>
          </div>
          <div
            className="px-5 flex justify-center leading-8 flex-col md:text-lg text-primaryGrey md:px-12 article-view"
            dangerouslySetInnerHTML={{ __html: article.description }}
          ></div>
        </div>
      ) : (
        <div className="h-[500px] flex items-center justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ArticleView;
