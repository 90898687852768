const HeadingMentalHealth = () => {
  return (
    <div>
      <div className="flex justify-center py-5 md:py-9">
        <p className="md:w-3/4 text-2xl md:text-4xl font-bold text-center">
          Berapa skor kesehatan mentalmu?
        </p>
      </div>

      <div className="flex flex-col items-center mb-5 md:mb-9">
        <p className="w-10/12 text-lg md:text-2xl font-light text-center">
          Di setiap nomor, pilih satu jawaban yang paling sesuai dengan
          pengalamanmu selama dua minggu belakangan ini.
        </p>
        <p className="w-10/12 text-lg md:text-2xl font-light text-center">
          Jujurlah tentang keadaanmu yang sesungguhnya agar mendapatkan gambaran
          dan saran yang tepat.
        </p>
      </div>
    </div>
  );
};

const HeadingStress = () => {
  return (
    <div className="flex items-center justify-center content-center">
      <div className="w-10/12">
        <div className="flex justify-center py-5 md:py-9">
          <p className="md:w-3/4 text-2xl md:text-4xl font-bold text-center">
            Berapa tingkat stress kamu saat ini?
          </p>
        </div>

        <div className="flex flex-col items-start mb-5 md:mb-9 text-justify">
          <p className="text-lg md:text-2xl font-light">
            Kuesioner ini terdiri dari berbagai pernyataan yang mungkin sesuai
            dengan pengalaman dan keadaan Anda. Terdapat 4 pilihan jawaban yang
            disediakan untuk setiap pernyataan yaitu:
          </p>
          <ol className="pl-5 md:pl-7 pt-2 mb-9 text-gray-600 list-decimal">
            <li className="text-lg md:text-2xl">
              Tidak sesuai dengan saya sama sekali, atau tidak pernah
            </li>
            <li className="text-lg md:text-2xl">
              Sesuai dengan saya sampai tingkat tertentu, atau kadang kadang
            </li>
            <li className="text-lg md:text-2xl">
              Memang sesuai dengan saya, atau lumayan sering
            </li>
            <li className="text-lg md:text-2xl">
              Memang sesuai dengan saya, atau lumayan sering
            </li>
          </ol>
          <p className="text-lg md:text-2xl font-light">
            Selanjutnya, Anda diminta untuk menjawab dengan cara memilih salah
            satu jawaban yang paling sesuai dengan pengalaman Anda selama satu
            minggu belakangan ini. Tidak ada jawaban yang benar ataupun salah,
            karena itu isilah sesuai dengan keadaan Anda yang sesungguhnya.
          </p>
        </div>
      </div>
    </div>
  );
};

const HeadingBurnedOut = () => {
  return (
    <div className="flex items-center justify-center content-center">
      <div className="w-10/12">
        <div className="flex justify-center py-5 md:py-9">
          <p className="md:w-3/4 text-2xl md:text-4xl font-bold text-center">
            Apakah aku sedang burnout?
          </p>
        </div>

        <div className="flex flex-col items-start mb-5 md:mb-9 text-justify">
          <p className="text-lg md:text-2xl font-light mb-6">
            Bak mesin yang terus dipaksa bekerja hingga akhirnya panas dan
            akhirnya malah tidak bisa berfungsi lagi, itulah fisik & mental kita
            ketika mengalami BURNOUT.
          </p>
          <p className="text-lg md:text-2xl font-light mb-6">
            Apakah ini kamu, KALMers? Apalagi kita sudah lebih dari setahun
            berada di tengah pandemi seperti saat ini. Burnout bisa
            mengakibatkan penyakit mental dan fisik, lho! Lebih baik dipastiin
            aja BERAPA PERSEN kemungkinan kamu sedang mengalami BURNOUT.
          </p>
          <p className="text-lg md:text-2xl font-light">
            Untuk setiap pertanyaan, pilih salah satu yang paling sesuai dengan
            kondisimu 1 MINGGU terakhir. Terdapat 4 pilihan jawaban yang
            disediakan untuk setiap pernyataan yaitu:
          </p>
          <ol className="pl-5 md:pl-7 pt-2 mb-9 text-gray-600 list-decimal">
            <li className="text-lg md:text-2xl">
              Tidak sama sekali, atau tidak pernah
            </li>
            <li className="text-lg md:text-2xl">Jarang</li>
            <li className="text-lg md:text-2xl">Terkadang</li>
            <li className="text-lg md:text-2xl">Sering</li>
            <li className="text-lg md:text-2xl">Sangat sering</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export { HeadingMentalHealth, HeadingStress, HeadingBurnedOut };
