import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DeviceTypeProvider } from "./context/DeviceTypeContext";
import config from "./config";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import BecomeKalmselor from "./pages/BecomeKalmselor";
import KalmForBusiness from "./pages/KalmForBusiness";
import ContactUs from "./pages/ContactUs";
import KalmQuiz from "./pages/KalmQuiz";
import Questions from "./pages/Questions";
import Register from "./pages/Register";
import AboutUs from "./pages/AboutUs";
import KalmselorList from "./pages/KalmselorList";
import KalmselorDetail from "./pages/KalmselorDetail";
import ArticleList from "./pages/ArticleList";
import ArticleView from "./pages/ArticleView";
import Questionnaire from "./pages/Questionnaire";
import QuestionnaireResult from "./pages/QuestionnaireResult";
import NotFound from "./pages/NotFound";

const {
  home,
  become,
  business,
  contact,
  quiz,
  faq,
  register,
  about,
  kalmselors,
  kalmselorDetail,
  articles,
  articleView,
  questionnaire,
  questionnaireResult,
  oldRoute,
} = config.pages;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <DeviceTypeProvider>
      <BrowserRouter>
        <ScrollToTop>
          <NavigationBar />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path={home} element={<Home />} />
            <Route path={become} element={<BecomeKalmselor />} />
            <Route path={business} element={<KalmForBusiness />} />
            <Route path={contact} element={<ContactUs />} />
            <Route path={quiz} element={<KalmQuiz />} />
            <Route path={faq} element={<Questions />} />
            <Route path={register} element={<Register />} />
            <Route path={about} element={<AboutUs />} />
            <Route path={articles} element={<ArticleList />} />
            <Route path={articleView} element={<ArticleView />} />
            <Route path={kalmselors} element={<KalmselorList />} />
            <Route path={kalmselorDetail} element={<KalmselorDetail />}></Route>
            <Route path={questionnaire} element={<Questionnaire />} />
            <Route
              path={questionnaireResult}
              element={<QuestionnaireResult />}
            />
            <Route path={oldRoute} element={<Home />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </DeviceTypeProvider>
  </React.StrictMode>
);
