import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import config from "../config";
import { fetchApiOld } from "../api";
import SearchBar from "../components/SearchBar";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
// @ts-ignore
import { find } from "lodash";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from "../assets/logo-dark.png";

const LIMIT = 12;
const UPPER_LIMIT = Number.MAX_SAFE_INTEGER;

const ArticleList = () => {
  const navigate = useNavigate();
  const [navPage, setNavPage] = useSearchParams();
  const [totalArticles, setTotalArticles] = useState(200);
  const [articles, setArticles] = useState<any[]>([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(Number(navPage.get("page")) || 1);

  useEffect(() => {
    getTotalArticles(selectedTitle);
    getArticles(selectedTitle, page);
  }, []);

  useEffect(() => {
    getTotalArticles(selectedTitle);
    getArticles(selectedTitle, page);
  }, [selectedTitle, page]);

  const getTotalArticles = (title: string) => {
    let params = [];
    if (selectedTitle) params.push(`search=${title}`);
    params.push(`perpage=${UPPER_LIMIT}`);
    const urlParams = params.length > 0 ? `${params.join("&")}` : "";
    fetchApiOld(config.oldApi.listArticles + "?" + urlParams).then(
      (response) => {
        let articles = response.data;
        setTotalArticles(articles.length);
      }
    );
  };

  const getArticles = (title: string, page: number) => {
    setIsLoading(true);
    let params = [];
    if (selectedTitle) params.push(`search=${title}`);
    if (page) params.push(`page=${page}`);
    params.push(`perpage=${LIMIT}`);
    const urlParams = params.length > 0 ? `${params.join("&")}` : "";
    fetchApiOld(config.oldApi.listArticles + "?" + urlParams)
      .then((response) => {
        let articles = response.data;
        setArticles(articles);
      })
      .finally(() => setIsLoading(false));
  };

  const resetURLParams = () => {
    setNavPage({});
  };

  const handleClickSearch = (item: string) => {
    setSelectedTitle(item);
    resetURLParams();
  };

  const handleClickView = (articleId: string) =>
    navigate(`/articles/${articleId}`, {
      state: {
        baseArticle: find(articles, { id: articleId }),
      },
    });

  // Logic for new pagination
  const onPageChanged = useCallback(
    (event: React.SyntheticEvent, page: number) => {
      event.preventDefault();
      setPage(page);
      window.scrollTo(0, 0);
    },
    [setPage]
  );

  return (
    <div className="mx-auto pb-5">
      <div className="flex justify-center py-5 md:py-10">
        <p className="md:w-1/2 text-2xl md:text-4xl text-primaryOrange font-bold text-center">
          KALM Artikel
        </p>
      </div>
      <div className="w-3/4 md:w-1/2 mx-auto my-10">
        <SearchBar handleSearchItem={handleClickSearch} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-wrap lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-8 w-3/4 mx-auto mb-6">
            {articles.map((article, index) => (
              <div
                key={index}
                className="rounded-lg my-3 w-full bg-primaryOrange relative"
              >
                <div className="hidden md:flex lg:hidden md:m-5">
                  <div className="flex flex-col w-1/2">
                    <LazyLoadImage
                        placeholderSrc={logo}
                        className="rounded-lg object-cover w-screen h-48 bg-primaryOrange"
                        alt="article-img"
                        effect="blur"
                        src={config.resourceApi.baseFileApiUrl + article.file}
                    />
                  </div>

                  <div className="flex flex-col w-1/2 px-6 py-4">
                    <p className="text-white text-xl py-4">{article.name}</p>
                    <button
                      onClick={() => handleClickView(article.id)}
                      className="border border-gray-300 text-sm shadow-sm text-gray-600 w-full lg:w-1/2 rounded-lg font-bold bg-white px-2 py-5"
                    >
                      Baca Artikel Lebih Lanjut
                    </button>
                  </div>
                </div>
                <div className="md:hidden lg:block grid grid-rows-2">
                  <div className="row-span-2">
                    <LazyLoadImage
                      placeholderSrc={logo}
                      className="bg-primaryOrange rounded-lg w-screen h-60 object-cover"
                      alt="article-img"
                      effect="blur"
                      src={config.resourceApi.baseFileApiUrl + article.file}
                    />
                  </div>
                  <div className="row-span-1 flex flex-col w-full px-5 py-4">
                    <p className="text-white text-2xl py-4">{article.name}</p>
                  </div>
                  <div className="h-14"></div>
                  <div className="absolute bottom-0 w-full p-4">
                    <button
                      onClick={() => handleClickView(article.id)}
                      className="w-full border border-gray-300 text-sm shadow-sm text-gray-600 h-1/3 rounded-lg font-bold bg-white px-2 py-5"
                    >
                      Baca Artikel Lebih Lanjut
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            totalRecords={totalArticles}
            pageLimit={LIMIT}
            pageNeighbours={2}
            onPageChanged={onPageChanged}
            currentPage={page}
          />
        </>
      )}
    </div>
  );
};

export default ArticleList;
