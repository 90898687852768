import React, { useEffect, useState } from "react";

type PaginationProps = {
  totalRecords: number;
  pageLimit: number;
  pageNeighbours: number;
  onPageChanged: (event: React.SyntheticEvent, page: number) => void;
  currentPage: number;
};
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
const range = (from: any, to: any, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = (props: PaginationProps) => {
  const {
    totalRecords,
    pageLimit,
    pageNeighbours,
    onPageChanged,
    currentPage,
  } = props;
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageLimit));
  }, [totalRecords]);

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const pages = fetchPageNumbers() || [];
  return (
    <div className="container flex justify-center mx-auto border border-black rounded-lg">
      <ul className="flex rounded-lg">
        {currentPage > 1 && (
          <li>
            <button
              onClick={(e) => onPageChanged(e, currentPage - 1)}
              className="h-10 px-5 text-gray-600 bg-white border border-r-0 rounded-l-lg border-gray-600 hover:bg-gray-100"
            >
              Prev
            </button>
          </li>
        )}

        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <li key={index} className="page-item">
                <button
                  className="h-10 px-5 text-gray-600 bg-white border border-r-0 border-gray-600 hover:bg-gray-100"
                  aria-label="Previous"
                  onClick={(e) => onPageChanged(e, pageNeighbours * 2 - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
            );

          if (page === RIGHT_PAGE)
            return (
              <li key={index} className="page-item">
                <button
                  className="h-10 px-5 text-gray-600 bg-white border border-r-0 border-gray-600 hover:bg-gray-100"
                  aria-label="Next"
                  onClick={(e) => onPageChanged(e, pageNeighbours * 2 + 3)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            );
          return (
            <li
              key={index}
              className={`page-item${currentPage === page ? " active" : ""}`}
            >
              <button
                className={`h-10 px-5 ${
                  currentPage === page
                    ? "text-white bg-gray-600"
                    : "text-gray-600 bg-white"
                } border border-r-0 border-gray-600 hover:bg-gray-100`}
                onClick={(e) => onPageChanged(e, page)}
              >
                {page}
              </button>
            </li>
          );
        })}
        {currentPage < totalPages && (
          <li>
            <button
              onClick={(e) => onPageChanged(e, currentPage + 1)}
              className="h-10 px-5 text-gray-600 bg-white border rounded-r-lg border-gray-600 hover:bg-gray-100"
            >
              Next
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
