import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const DropdownMenu = ({ title, items, selectedItem, handleSelectItem }: any) => {
  return (
    <Menu as="div" className="inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {title}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="origin-top-right absolute overflow-y-auto max-h-64 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[100]">
          <div className="py-1">
            {items.map((item: any, idx: any) => (
              <Menu.Item key={idx}>
                {({ active }) => (
                  <div className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          selectedItem === item.id && 'bg-primaryOrange text-primaryGray',
                          'block px-4 py-2 text-sm cursor-pointer'
                       )}
                       onClick={() => handleSelectItem(item)}>
                    {item.title}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropdownMenu