import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../config";
import logo from "../assets/logo.png";

const lgNavBarLinkClass =
  "my-auto md:px-2 lg:px-5 font-medium hover:text-primaryOrange";
const mobileNavBarLinkClass = "flex hover:bg-white hover:text-primaryBlue w-full font-medium justify-center";

const NavigationBar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    quiz,
    about,
    articles,
    business,
    contact,
    kalmselors,
    become,
  } = config.pages;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickLogo = () => {
    navigate(config.pages.home);
  };

  return (
    <div className="sticky top-0 mx-auto z-[1000]">
      <nav className="flex flex-col md:flex-row items-center justify-evenly bg-white drop-shadow">
        <div className="flex flex-row justify-items-center justify-between md:justify-center w-full md:w-fit">
          <div className="mx-4 py-0">
            <img
              src={logo}
              className="object-contain w-24 md:w-28 lg:w-32 h-24 md:h-28 lg:h-32 cursor-pointer"
              onClick={handleClickLogo}
              alt="logo"
            />
          </div>

          <div className="relative flex flex-col px-3 inset-0 items-center content-center justify-center">
            <button
              id="menu-btn"
              className="hamburger md:hidden mt-2 focus:outline-none"
              onClick={toggleDropdown}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </button>
          </div>
        </div>
        <div className="hidden md:flex flex-col md:flex-row justify-between text-primaryBlue">
          <Link to={quiz} className={lgNavBarLinkClass}>
            KALM Quiz
          </Link>
          <span className={`group relative inline-block my-auto`}>
            <Link
              to={about}
              className={`inline-flex items-center ${lgNavBarLinkClass}`}
            >
              Tentang KALM
            </Link>
            <ul className="absolute hidden pt-2 text-primaryBlue group-hover:block w-40 drop-shadow-sm">
              <li className="bg-white">
                <Link
                  to={kalmselors}
                  className="whitespace-no-wrap block py-4 px-4 hover:bg-primaryBlue hover:text-white"
                >
                  List Kalmselor
                </Link>
              </li>
              <li className="bg-white">
                <Link
                  to={become}
                  className="whitespace-no-wrap block py-4 px-4 hover:bg-primaryBlue hover:text-white"
                >
                  Jadi Kalmselor
                </Link>
              </li>
            </ul>
          </span>
          <Link to={articles} className={lgNavBarLinkClass}>
            KALM Artikel
          </Link>
          <Link to={business} className={lgNavBarLinkClass}>
            KALM for Business
          </Link>
          <Link to={contact} className={lgNavBarLinkClass}>
            Hubungi Kami
          </Link>
          <Link to={register} className="my-auto md:px-2 lg:px-5">
            <button className="my-auto bg-primaryOrange hover:bg-transparent w-auto text-white font-semibold hover:text-primaryOrange mr-4 lg:mr-0 py-3 md:px-5 border border-primaryOrange rounded-3xl">
              Daftar Konseling
            </button>
          </Link>
        </div>
      </nav>

      {isOpen && (
        <nav className="md:hidden flex flex-col items-center text-white bg-primaryBlue drop-shadow-md">
          <div className={mobileNavBarLinkClass}>
            <Link to={quiz} onClick={toggleDropdown} className="py-5">
              Kalm Quiz
            </Link>
          </div>
          <span className="group relative inline-block w-full">
            <div className={mobileNavBarLinkClass}>
              <Link to={about} onClick={toggleDropdown} className="py-5">
                Tentang KALM
              </Link>
            </div>
            <ul className="hidden pt-2 text-primaryBlue group-hover:flex group-focus:flex group-active:flex flex-col w-full shadow-inner ">
              <li className="bg-white w-full justify-center">
                <Link
                  to={kalmselors}
                  onClick={toggleDropdown}
                  className="whitespace-no-wrap flex py-4 px-4 hover:underline justify-center"
                >
                  List Kalmselor
                </Link>
              </li>
              <li className="bg-white">
                <Link
                  to={become}
                  onClick={toggleDropdown}
                  className="whitespace-no-wrap flex py-4 px-4 hover:underline justify-center"
                >
                  Jadi Kalmselor
                </Link>
              </li>
            </ul>
          </span>
          <div className={mobileNavBarLinkClass}>
            <Link to={articles} onClick={toggleDropdown} className="py-5">
              KALM Artikel
            </Link>
          </div>
          <div className={mobileNavBarLinkClass}>
            <Link to={business} onClick={toggleDropdown} className="py-5">
              KALM for Business
            </Link>
          </div>
          <div className={mobileNavBarLinkClass}>
            <Link to={contact} onClick={toggleDropdown} className="py-5">
              Hubungi Kami
            </Link>
          </div>
          <div className={mobileNavBarLinkClass}>
            <Link
                to={register}
                onClick={toggleDropdown}
                className="mt-2 mb-5"
            >
              <button className="bg-primaryOrange hover:bg-transparent w-auto text-white font-semibold hover:text-primaryOrange py-3 px-4 border border-primaryOrange rounded-3xl">
                Daftar Konseling
              </button>
            </Link>
          </div>
        </nav>
      )}
    </div>
  );
};

export default NavigationBar;
