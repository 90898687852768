import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { Reason } from "../types/Reason";
import { Tutorial } from "../types/Tutorial";
import heroImage from "../assets/about-hero.jpeg";
import firstReason from "../assets/about-reason-1.png";
import secondReason from "../assets/about-reason-2.jpeg";
import thirdReason from "../assets/about-reason-3.png";
import aboutHero from "../assets/become-hero.png";

const AboutUs = () => {
  const navigate = useNavigate();

  const reasons: Reason[] = [
    {
      image: firstReason,
      text: "Bisa mendapatkan pendampingan profesional secara berkala",
    },
    {
      image: secondReason,
      text:
        "Didampingi oleh Konselor dan Psikolog yang memiliki sertifikasi dan izin praktik",
    },
    {
      image: thirdReason,
      text: "Harga konseling yang terjangkau",
    },
  ];

  const tutorials: Tutorial[] = [
    {
      title: "Tutorial Aplikasi Kalm",
      link: "https://www.youtube.com/embed/QR4k5EubHak",
    },
    {
      title: "Tutorial Pembayaran",
      link: "https://www.youtube.com/embed/o33ybTGfdrA",
    },
    {
      title: "Tutorial Memulai Konseling Chat",
      link: "https://www.youtube.com/embed/l1a9GgCL1PM",
    },
    {
      title: "Tutorial Mengganti Kalmselor",
      link: "https://www.youtube.com/embed/l1a9GgCL1PM",
    },
  ];

  const handleClickListKalmselorButton = () =>
    navigate(config.pages.kalmselors);

  const handleClickBecomeKalmselorButton = () => navigate(config.pages.become);

  const handleClickCollaborateButton = () => navigate(config.pages.business);

  return (
    <div className="mx-auto w-10/12 text-primaryGrey">
      {/*Hero Section*/}
      <div className="flex flex-col-reverse lg:flex-row justify-evenly items-center p-5 gap-6">
        <div className="flex flex-col p-4 lg:w-1/2 text-left">
          <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
            KALM adalah platform konseling online yang berpusat di Indonesia.
            Kami hadir untuk memberikan tempat yang aman dan nyaman agar kamu
            bisa bebas bercerita dan mencari jalan keluar dari masalah apa pun
            yang kamu alami.{" "}
          </p>
          <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
            Dengan KALM kamu bisa mengakses layanan dari konselor profesional
            secara privat dan praktis dengan harga yang terjangkau.
          </p>
          <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
            Dapatkan konseling profesional kapan pun, dimana pun, hanya dengan
            sentuhan jari!
          </p>
        </div>

        <div className="object-fit lg:w-1/2">
          <img src={heroImage} alt="hero-image" />
        </div>
      </div>

      {/*Reason Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10 bg-primaryBlue">
        <p className="pb-10 text-2xl md:text-4xl text-white text-center font-bold">
          Kenapa Harus Konseling di KALM?
        </p>
        <div className="flex flex-wrap lg:flex-row justify-center">
          {reasons.map((reason, index) => (
            <div
              key={index}
              className="max-w-xs rounded overflow-hidden shadow-lg my-3 mx-2 md:mx-5 bg-primaryYellow"
            >
              <img
                className="w-full bg-white h-2/3"
                src={reason.image}
                alt="reason-image"
              />
              <div className="flex justify-center items-center px-6 py-4 w-full h-1/3">
                <p className="text-gray-600 text-xl text-center">
                  {reason.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*Tutorial Section*/}
      <div className="flex flex-wrap justify-center items-center p-5">
        {tutorials.map((tutorial, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center md:w-2/5 m-5"
          >
            <p className="p-2.5 md:p-5 text-xl lg:text-2xl text-center font-bold">
              {tutorial.title}
            </p>
            <iframe
              width="560"
              height="315"
              src={tutorial.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-screen md:w-full"
            ></iframe>
          </div>
        ))}
      </div>

      {/*About Section*/}
      <div className="flex flex-col lg:flex-row justify-center items-center p-5 gap-6">
        <div className="flex justify-center lg:w-1/2">
          <div className="object-fit">
            <img src={aboutHero} alt="about-hero" />
          </div>
        </div>

        <div className="lg:w-1/2">
          <div className="flex flex-col p-4 text-left">
            <p className="py-2 text-2xl md:text-4xl font-bold">
              Siapa itu Kalmselor?
            </p>
            <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
              Kalmselor adalah konselor profesional berpengalaman yang sudah
              terdaftar dalam platform KALM. Setiap Kalmselor telah melewati
              progress pemilihan sebelum mereka bergabung dengan platform KALM
              dimana kredensial mereka telah diverifikasi sebelum mereka
              disetujui untuk memberikan pelayanan mereka di KALM.
            </p>
            <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
              Kalmselor kami adalah seseorang yang telah terakreditasi dengan
              ijazah S2 Psikologi/Psikiatri/Konseling/memiliki Izin praktik
              sebagai psikolog.
            </p>
            <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
              Ingin tau lebih lanjut soal Kalmselor kami? atau ingin bergabung?
              Silahkan klik tombol dibawah ini.
            </p>

            <div className="my-2.5">
              <button
                className="bg-white hover:bg-primaryBlue md:w-1/3 lg:w-1/2 text-lg md:text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
                onClick={handleClickListKalmselorButton}
              >
                Lihat Kalmselor
              </button>
            </div>

            <div className="my-2.5">
              <button
                className="bg-primaryBlue hover:bg-white md:w-1/3 lg:w-1/2 text-lg md:text-lg text-white font-semibold hover:text-primaryBlue py-2 px-4 md:px-8 border border-transparent hover:border-primaryBlue rounded"
                onClick={handleClickBecomeKalmselorButton}
              >
                Jadi Kalmselor
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*End Section*/}
      <div className="flex flex-col lg:flex-row justify-center p-5 md:p-10 bg-primaryBlue mb-10">
        <div className="flex flex-col lg:w-1/2 px-5 mb-6">
          <p className="mb-5 text-4xl font-bold text-white">
            Apakah Ada Karyawan Anda yang Menunjukkan Gejala di bawah Ini?
          </p>
          <ol className="m-2.5 text-lg font-light text-white list-decimal">
            <li>Sering terlambat atau terus-menerus mengambil cuti</li>
            <li>Perubahan dalam output pekerjaan dan motivasi</li>
            <li>Tampak bingung dan susah berkonsentrasi</li>
            <li>
              Kesulitan dalam mengambil keputusan, berorganisasi, dan mencari
              solusi permasalahan
            </li>
            <li>Mudah terpicu dan marah</li>
            <li>
              Perubahan dalam tingkah laku atau bagaimana mereka berinteraksi
              dengan kolega
            </li>
            <li>Terlihat capai, cemas, atau menyendiri</li>
            <li>
              Konsumsi rokok dan minuman keras meningkat dan/atau ada perubahan
              pola makan
            </li>
            <li>
              Gejala-gejala fisik yang tidak jelas alasannya (pusing, sakit
              kepala, jantung berdegup, rasa kebas pada bagian tubuh)
            </li>
            <li>Mengutarakan keinginan bunuh diri</li>
          </ol>
          <p className="mb-5 text-lg font-light text-white">
            Jika IYA, mereka membutuhkan bantuan profesional sekarang juga!
          </p>
          <button
            className="bg-white hover:bg-primaryBlue md:w-full text-md text-primaryBlue font-semibold hover:text-white py-4 px-4 md:px-8 my-5 border border-primaryBlue hover:border-white rounded"
            onClick={handleClickCollaborateButton}
          >
            Kerja sama dengan kami
          </button>
        </div>

        <div className="flex w-full lg:w-1/2 items-center justify-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/19PXmoF6mbo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default AboutUs
