import { Oval } from "react-loader-spinner";
import React from "react";

const Loader = () => {
  return (
    <div className="flex items-center justify-center content-center p-12">
      <Oval
        height={80}
        width={80}
        color="#D78B72"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#rgba(215, 139, 114, 0.5)"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default Loader;
