import React, { Fragment, useEffect, useState } from "react";
import config from "../config";
import { Subscription } from "../types/Subscription";
import logo from "../assets/logo.png";
import playStoreLogo from "../assets/play-store.png";
import appStoreLogo from "../assets/app-store.png";
import firstFeed from "../assets/register-1.jpg";
import secondFeed from "../assets/register-2.png";
import imgOfflineCounseling from "../assets/register/img_offline_counseling.png";
import checkIcon from "../assets/checkmark.svg";
import { Dialog, Tab, Transition } from "@headlessui/react";
import {
  ChatIcon,
  InformationCircleIcon,
  MapIcon as MapIconSolid,
  PlayIcon,
} from "@heroicons/react/solid";
import step_1 from "../assets/register/steps/icon_alur_1.png";
import step_2 from "../assets/register/steps/icon_alur_2.png";
import step_3 from "../assets/register/steps/icon_alur_3.png";
import step_4 from "../assets/register/steps/icon_alur_4.png";
import step_5 from "../assets/register/steps/icon_alur_5.png";
import step_6 from "../assets/register/steps/icon_alur_6.png";
import { GuideStep } from "../types/GuideStep";
import { MapIcon, PhoneIcon } from "@heroicons/react/outline";
import { useLocation, useNavigate } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const tabPanelClassName = ({ selected }: { selected: boolean }) =>
  classNames(
    "w-full rounded-3xl py-2.5 font-bold leading-5 text-primaryOrange",
    "ring-white ring-opacity-60 focus:outline-none ",
    selected
      ? "bg-white shadow"
      : "text-alternativeBlack font-bold hover:bg-white/[0.12] hover:text-primaryOrange"
  );

const offlineCounselingSectionRef = React.createRef<HTMLDivElement>();

const steps: GuideStep[] = [
  {
    order: 1,
    image: step_1,
    title: "Daftar & Isi Form Pra-Konseling",
    text: (
      <p>
        Klik tombol{" "}
        <button
          onClick={() => {
            if (
              offlineCounselingSectionRef &&
              offlineCounselingSectionRef.current
            ) {
              offlineCounselingSectionRef.current.scrollIntoView();
            }
          }}
          className="text-primaryOrange font-semibold"
        >
          Daftar Konseling Offline
        </button>
        , selanjutnya isi formulir pra-konseling tatap muka KALM. Kamu akan
        mendapatkan link booking konseling diakhir formulir.
      </p>
    ),
  },
  {
    order: 2,
    image: step_2,
    title: "Pilih Jenis & Waktu Konseling",
    text: (
      <p>
        Pilih jenis konseling yang kamu butuhkan, serta tentukan juga tanggal
        dan waktu konseling. Konseling akan dilaksanakan di{" "}
        <a
          href={config.external.offlineCounseling.map}
          target="_blank"
          className="text-primaryOrange font-semibold"
        >
          Start Space Kemanggisan, Kota Jakarta Barat
        </a>
      </p>
    ),
  },
  {
    order: 3,
    image: step_3,
    title: "Pilih Kalmselor & Bayar",
    text: (
      <p>
        Pilihan konselor akan disesuaikan dengan tanggal dan waktu yang kamu
        pilih. Setelah itu kamu dapat melakukan pembayaran.
      </p>
    ),
  },
  {
    order: 4,
    image: step_4,
    title: "Whatsapp Informasi Pendaftaran",
    text: (
      <p>
        Setelah pembayaran berhasil kamu akan mendapatkan pesan whatsapp
        informasi pendaftaran konselingmu.
      </p>
    ),
  },
  {
    order: 5,
    image: step_5,
    title: "Datang Tepat Waktu",
    text: (
      <p>Pastikan datang tepat waktu sesuai tanggal dan jam yang kamu pilih.</p>
    ),
  },
  {
    order: 6,
    image: step_6,
    title: "Konseling & Ceritakan Masalahmu",
    text: (
      <p>
        Cerita dan konsultasikan apapun masalahmu kepada Kalmselor{" "}
        <span className="text-primaryOrange font-semibold">
          #KitaPerluCerita
        </span>
      </p>
    ),
  },
];

const reasons: string[] = [
  "Kamu lebih nyaman ngobrol secara langsung",
  "Konselormu butuh asesmen tambahan untuk keperluan diagnosis dan tindakan lanjutan",
  "Terapi untuk masalah yang kamu hadapi tidak dapat diterapkan secara online",
  "Membutuhkan diagnosis yang lebih spesifik untuk solusi yang lebih tepat dan intens",
  "Kamu membutuhkan pertemuan tatap muka karena kondisi yang harus ditangani secara langsung",
];

const facilities: string[] = [
  "Privasi Terjaga",
  "Tempat parkir luas",
  "Kamar Mandi",
  "Cafe di lantai bawah",
];

const chats: Subscription[] = [
  {
    type: "chat-3",
    title: "Langganan 3 Hari",
    desc: "Untuk kamu yang mau coba",
    price: "Rp 99.000",
    items: [
      "Cocok untuk yang baru pertama kali On-Going Counseling",
      "Kalmselor akan menjawab min. 2 kali secara substansial (24 jam).",
      "Pendampingan berkala selama 3 hari.",
      "Kamu bisa mendapatkan tips praktis dalam waktu singkat",
    ],
    styleOptions: {
      titleColor: "text-[#E6B363]",
      containerColor: "bg-primaryBlue",
    },
  },
  {
    type: "chat-7",
    title: "Langganan 7 Hari",
    desc: "Untuk kamu yang mau lanjut",
    price: "Rp 198.000",
    items: [
      "Cocok untuk yang ingin melanjutkan konseling 3 hari.",
      "Kalmselor akan menjawab min. 2 kali secara substansial (24j am)",
      "Pendampingan berkala selama 7 hari",
      "Dapat membahas masalah lebih dalam",
    ],
    styleOptions: {
      titleColor: "text-white",
      containerColor: "bg-primaryGreen",
    },
  },
  {
    type: "chat-28",
    title: "Langganan 28 Hari",
    desc: "Untuk kamu yang mau intensif",
    price: "Rp 752.000",
    items: [
      "Cocok untuk maintenance dan yang mau bertumbuh.",
      "Kalmselor akan menjawab min. 2 kali secara substansial (24 jam)",
      "Pendampingan berkala selama 28 hari",
      "Serasa punya psikolog pribadi",
    ],
    styleOptions: {
      titleColor: "text-[#D78B72]",
      containerColor: "bg-primaryBlue",
    },
  },
];

const videos: Subscription[] = [
  {
    type: "video-single",
    title: "1on1 Counseling",
    desc: "Untuk yang pertama kali",
    price: "Rp 350.000",
    items: [
      "50 menit Via Zoom",
      "Interaksi tatap muka  real-time",
      "Di ruangan hanya Kamu dan konselor",
    ],
  },
  {
    type: "video-couple",
    title: `Couple's Counseling`,
    desc: "Untuk kamu dan pasangan",
    price: "Rp 500.000",
    items: [
      "75 Menit Via Zoom",
      "Interaksi real-time",
      "Berdua bersama Pasangan",
    ],
  },
];

const offlineCounselingSections: Subscription[] = [
  {
    type: "offline-single",
    title: "Konseling Individu",
    desc: "Ceritakan dirimu",
    price: "Rp 388.000,-",
    items: [
      "Asesmen pre-konseling",
      "Konsultasi hasil",
      "Konseling sendiri/individu",
      "Durasi 60 menit",
    ],
  },
  {
    type: "offline-couple",
    title: `Konseling Pasangan`,
    desc: "Untuk kamu dan pasangan",
    price: "Rp 628.000,-",
    items: [
      "Asesmen pre-konseling",
      "Konsultasi hasil",
      "Konseling berdua bersama dengan pasangan",
      "Durasi 90 menit",
    ],
  },
];

const tabsEnum = {
  chat: "#chat-counseling",
  video: "#video-counseling",
  offline: "#offline-counseling",
};

const tabIndex = {
  [tabsEnum.chat]: 0,
  [tabsEnum.video]: 1,
  [tabsEnum.offline]: 2,
};

const indexTab = {
  [0]: tabsEnum.chat,
  [1]: tabsEnum.video,
  [2]: tabsEnum.offline,
};

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  let [showTutorialVideoModal, setTutorialVideoModal] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setSelectedIndex(tabIndex[location.hash]);
  }, [location.hash]);

  function closeTutorialVideoModal() {
    setTutorialVideoModal(false);
  }

  function openTutorialVideoModal() {
    setTutorialVideoModal(true);
  }

  const handleClickTryAppButton = () =>
    (window.location.href = "https://bit.ly/KALMapp");

  const handleClickRegisterSingleButton = () =>
    (window.location.href = "https://kalm-app.com/");

  const handleClickRegisterCoupleButton = () =>
    (window.location.href = "http://bit.ly/KALMCoupleCouns");

  const handleClickPlayStore = () =>
    (window.location.href = config.external.playStore);

  const handleClickAppStore = () =>
    (window.location.href = config.external.appStore);

  const handleClickOfflineCounselingRegister = () =>
    window.open(config.external.offlineCounseling.preCounselingForm, "_blank");

  const handleClickOfflineCounselingMap = () =>
    window.open(config.external.offlineCounseling.map, "_blank");

  const handleClickOfflineCounselingContact = () =>
    window.open(config.external.offlineCounseling.whatsapp, "_blank");

  return (
    <div className="w-full mt-16">
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(selectedIndex) => {
          // @ts-ignore
          navigate(location.pathname + indexTab[selectedIndex], {
            replace: true,
          });
          setSelectedIndex(selectedIndex);
        }}
      >
        <Tab.List className="flex space-x-1 rounded-full bg-softOrange p-3 lg:w-1/3 mx-5 lg:mx-auto">
          <Tab className={({ selected }) => tabPanelClassName({ selected })}>
            Konseling Chat
          </Tab>
          <Tab className={({ selected }) => tabPanelClassName({ selected })}>
            Konseling Video
          </Tab>
          <Tab className={({ selected }) => tabPanelClassName({ selected })}>
            Konseling Offline
          </Tab>
        </Tab.List>
        <Tab.Panel>
          <div className="mx-auto mt-6 text-primaryGrey">
            <div className="flex justify-center py-5 md:py-10">
              <p className="md:w-3/4 text-2xl md:text-4xl font-bold text-center">
                Apa yang membuat Chat Counseling di KALM berbeda dengan yang
                lain?
              </p>
            </div>

            <div className="flex flex-col items-center py-5 md:py-10">
              <p className="w-10/12 my-2.5 text-lg md:text-2xl font-light text-center">
                KALM Chat Counseling menggunakan{" "}
                <b className="font-bold text-primaryOrange">Sistem On-Going</b>,
                di mana{" "}
                <b className="text-primaryOrange font-bold">
                  layanan konseling yang berlangsung minimal 3 hari
                </b>
                {" dan "}
                <b className="text-primaryOrange font-bold">
                  balasan tidak bersifat real-time
                </b>
                .
              </p>
              <p className="w-10/12 my-2.5 text-lg md:text-2xl font-light text-center">
                <b>
                  Kamu akan mendapatkan balasan dari Kalmselor setidaknya dua
                  momen sehari, contohnya Pagi dan Malam
                </b>
              </p>
              <p className="w-10/12 my-2.5 text-lg md:text-2xl font-light text-center">
                Selama konseling berlangsung, Klien dan Kalmselor sama-sama
                memiliki jeda yang dapat digunakan untuk merefleksikan jawaban
                atau mencoba saran praktis yang telah diberikan oleh Kalmselor.
              </p>
            </div>

            {/*Promo Feed Section*/}
            <div className="flex flex-col lg:flex-row justify-center py-5 md:py-10">
              <div className="lg:w-1/3 my-5 mx-5">
                <img src={firstFeed} alt="first-feed" />
              </div>
              <div className="lg:w-1/3 my-5 mx-5 ">
                <img src={secondFeed} alt="second-feed" />
              </div>
            </div>

            {/*Chat Options Section*/}
            <div className="flex flex-col justify-center py-5 md:py-10 bg-cover bg-[url('/src/assets/cloud-background.jpeg')]">
              <p className="p-10 text-3xl text-center font-bold">
                Yuk Langganan Chat Counseling Sekarang!
              </p>

              <div className="flex flex-col lg:flex-row justify-center items-center lg:items-stretch">
                {chats.map((chat: Subscription, index: number) => (
                  <div
                    key={index}
                    className={`flex flex-col items-center max-w-sm px-5 py-5 mx-5 my-5 lg:my-0 bg-primaryBlue ${chat.styleOptions?.containerColor}`}
                  >
                    <p
                      className={`mt-2.5 mb-1 text-xl md:text-2xl ${chat.styleOptions?.titleColor} font-bold`}
                    >
                      {chat.title}
                    </p>
                    <p className="w-full my-1 py-1 text-md md:text-lg font-light text-primaryBlue text-center bg-[#FFF]">
                      {chat.desc}
                    </p>
                    <p className="py-5 text-xl md:text-2xl text-white font-bold">
                      {chat.price}
                    </p>
                    <div className="flex flex-col md:py-2.5">
                      {chat.items.map((item: string, index: number) => (
                        <div
                          key={index}
                          className="flex justify-center items-center"
                        >
                          <div className="w-1/6">
                            <img
                              src={checkIcon}
                              className="w-20 h-20"
                              alt="check"
                            />
                          </div>
                          <p className="w-5/6 py-1 md:py-2 px-2.5 text-md md:text-lg font-light text-white break-words">
                            {item}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col items-center py-10">
                <div className="flex">
                  <button
                    className="bg-transparent hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 border border-primaryBlue hover:border-transparent rounded"
                    onClick={handleClickTryAppButton}
                  >
                    Coba Aplikasi
                  </button>
                </div>

                <div>
                  <img src={logo} className="object-fit w-48 h-48" alt="logo" />
                </div>

                <div className="flex flex-row">
                  <img
                    src={playStoreLogo}
                    alt="play-store"
                    className="object-fit w-24 md:w-36 h-10 md:h-12 mx-5 cursor-pointer"
                    onClick={handleClickPlayStore}
                  />
                  <img
                    src={appStoreLogo}
                    alt="app-store"
                    className="object-fit w-24 md:w-36 h-10 md:h-12 mx-5 cursor-pointer"
                    onClick={handleClickAppStore}
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab.Panel>
        <Tab.Panel>
          {/*Video Counseling Section*/}
          <div className="py-10 md:mb-24">
            <p className="mb-10 text-2xl md:text-4xl font-bold text-center">
              Video Counseling
            </p>
            <div className="flex flex-col md:flex-row justify-center">
              {videos.map((video, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div className="flex flex-col items-center max-w-sm min-h-full max-h-min px-5 py-5 mx-5 my-5 md:my-0 bg-primaryOrange">
                    <p className="mt-2.5 mb-1 text-xl md:text-2xl text-white font-bold">
                      {video.title}
                    </p>
                    <p className="w-full my-1 py-1 text-md md:text-lg font-light text-primaryOrange text-center bg-[#FFF]">
                      {video.desc}
                    </p>
                    <p className="py-5 text-xl md:text-2xl text-white font-bold">
                      {video.price}
                    </p>
                    <div className="flex flex-col md:py-2.5">
                      {video.items.map((item: any, index: any) => (
                        <div
                          key={index}
                          className="flex justify-center items-center"
                        >
                          <div className="w-1/6">
                            <img
                              src={checkIcon}
                              className="w-20 h-20"
                              alt="check"
                            />
                          </div>
                          <p className="w-5/6 py-1 md:py-2 px-2.5 text-lg md:text-lg font-light text-white break-words">
                            {item}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex justify-center w-full my-5">
                    <button
                      className="bg-transparent hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 border border-primaryBlue hover:border-transparent rounded"
                      onClick={
                        video.type === "video-single"
                          ? handleClickRegisterSingleButton
                          : handleClickRegisterCoupleButton
                      }
                    >
                      Daftar Di sini
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tab.Panel>
        <Tab.Panel>
          <div
            ref={offlineCounselingSectionRef}
            className="mx-auto mt-6 text-primaryGrey w-11/12"
          >
            <div className="flex justify-center py-5 md:py-10">
              <p className="md:w-3/4 text-2xl md:text-4xl font-bold text-center">
                Ceritakan Semua Yang Terpendam{" "}
                <b className="text-primaryOrange">#KitaPerluCerita</b>
              </p>
            </div>
            <div className="flex flex-col-reverse lg:flex-row gap-4 justify-center py-5 md:py-10">
              <div className="lg:w-1/2 h-full xl:h-auto my-5 mx-5 p-2 bg-softOrange rounded-xl">
                <div className="flex h-4/5 flex-col md:flex-row gap-8 justify-center p-1 mb-4 md:p-7">
                  {offlineCounselingSections.map((section, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center w-full"
                    >
                      <div className="flex flex-col items-center text-center md:my-0 bg-white p-3 xl:p-7 h-full w-full rounded-xl">
                        <p className="text-md md:text-xl xl:text-2xl items-center text-alternativeBlack font-bold">
                          {section.title}
                        </p>
                        <p className="w-full my-1 py-1 text-md md:text-2xl font-bold text-primaryOrange text-center bg-[#FFF]">
                          {section.price}
                        </p>
                        <div className="w-full flex flex-col md:py-2.5 mt-4 text-start">
                          {section.items.map((item: any, index: any) => (
                            <div key={index} className="flex items-center">
                              <div className="w-1/8">
                                <img
                                  src={checkIcon}
                                  className="w-4 h-4 md:w-6 md:h-6"
                                  alt="check"
                                />
                              </div>
                              <p className="w-5/6 py-1 text-sm md:text-base font-medium md:py-2 pl-2.5 leading-5 text-alternativeBlack break-words">
                                {item}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex-col justify-center w-full mb-5 mx-auto px-1 md:px-7">
                  <div className="flex mb-6">
                    <InformationCircleIcon className="h-8 w-8 mr-2 pb-2 text-primaryBlue" />{" "}
                    Pemesanan hanya bisa dilakukan H-3 dari jadwal konseling.
                    Jadi kamu bisa daftar sekarang untuk konseling offline di 3
                    hari lagi.
                  </div>
                  <button
                    className="w-full bg-primaryOrange hover:bg-white text-md md:text-lg text-white font-semibold hover:text-primaryOrange py-2 px-4 border border-primaryOrange rounded-full"
                    onClick={handleClickOfflineCounselingRegister}
                  >
                    Daftar Konseling Offline
                  </button>
                </div>
              </div>
              <div className="lg:w-1/2 my-5 mx-5 bg-softOrange rounded-xl">
                <img
                  src={imgOfflineCounseling}
                  alt="second-feed"
                  className="object-cover object-bottom mx-auto w-full h-full rounded-xl"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center p-5 pt-16 bg-primaryOrange">
            <p className="text-2xl md:text-4xl font-bold text-center text-white">
              Cara Konseling Offline di KALM
            </p>
            <div className="flex flex-col justify-center w-full items-center p-8 mb-4">
              <button
                className="flex flex-row gap-3 bg-secondaryBlue hover:bg-white text-white font-semibold hover:text-secondaryBlue py-2.5 px-5 border border-2 border-secondaryBlue rounded-full"
                onClick={openTutorialVideoModal}
              >
                <PlayIcon className="w-5 h-5"></PlayIcon>
                Lihat Video Tutorial
              </button>
            </div>
            <div className="flex flex-wrap xl:flex-row gap-8 mb-16 justify-center">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row w11/12 sm:w-10/12 lg:w-[45%] rounded-xl p-4 overflow-hidden justify-start shadow-lg bg-white"
                >
                  <img
                    className="mx-auto mb-2 w-16 h-16 md:w-28 md:h-28"
                    src={step.image}
                    alt="reason-image"
                  />
                  <div className="flex flex-col pl-6 justify-center">
                    <div className="flex items-center mb-2">
                      <div className="font-semibold text-xl text-alternativeBlack text-left">
                        {step.title}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="font-medium text-alternativeBlack text-left">
                        {step.text}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center p-5 pt-16">
            <p className="mb-16 text-2xl md:text-4xl font-bold text-center">
              Kamu Harus{" "}
              <span className="text-primaryOrange">Konseling Offline</span> Jika
              ...
            </p>
            <div className="flex flex-col gap-1 mb-16 rounded-xl justify-center border-solid border border-gray-300 p-4">
              {reasons.map((reason, index) => (
                <div
                  key={index}
                  className="flex flex-row w-full p-4 justify-start"
                >
                  <div className="relative w-5 h-5 bg-primaryOrange rounded-full flex justify-center items-center text-center p-3 font-medium text-white mt-0.5">
                    {index + 1}
                  </div>
                  <div className="flex flex-col pl-4 justify-center w-full">
                    <div
                      className={`flex items-center ${
                        index + 1 === reasons.length
                          ? ""
                          : "pb-6 border-solid border-gray-300 border-b"
                      }`}
                    >
                      <p className="font-semibold text-alternativeBlack text-left text-xl">
                        {reason}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center p-5 pt-16 pb-12 bg-softOrange">
            <p className="mb-16 text-2xl md:text-4xl font-bold text-center text-alternativeBlack">
              Informasi Lebih Lanjut
            </p>
            <div className="flex flex-col lg:flex-row  p-8 mb-16 w-11/12 justify-center lg:justify-between bg-white rounded-2xl">
              <div className="flex flex-row rounded-full p-4 mb-4 lg:mb-0 justify-start">
                <PhoneIcon className="w-7 h-7 text-primaryOrange"></PhoneIcon>
                <div className="flex flex-col pl-6 justify-center">
                  <div className="flex items-center">
                    <p className="font-bold text-2xl text-alternativeBlack text-left">
                      Hubungi KALM
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={handleClickOfflineCounselingContact}
                className="flex flex-row md:w-3/4 mx-auto lg:mx-0 lg:w-auto rounded-full bg-green-600 px-6 lg:px-14 py-3 justify-center items-center"
              >
                <ChatIcon className="w-7 h-7 text-white"></ChatIcon>
                <div className="flex flex-col pl-3 justify-center">
                  <div className="flex items-center">
                    <p className="font-bold text-white text-left">
                      KALM Admin (WA) - 0812 1400 7943
                    </p>
                  </div>
                </div>
              </button>
            </div>
            <div className="flex flex-col sm:p-8 mb-16 w-11/12 bg-white rounded-2xl">
              <div className="flex flex-col sm:flex-row rounded-full p-4 justify-start w-full">
                <MapIcon className="mx-auto w-12 h-12 sm:w-7 sm:h-7 mt-0.5 text-primaryOrange"></MapIcon>
                <div className="flex flex-col pl-6 w-full justify-center">
                  <div className="flex items-center mb-6">
                    <p className="font-bold text-2xl text-alternativeBlack text-left">
                      Lokasi Konseling Offline KALM
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row w-full justify-start">
                    <div className="flex flex-col gap-4 mb-8 md:mb-0 justify-between w-full lg:w-1/2 mr-12 items-start">
                      <p className="font-bold text-xl text-alternativeBlack text-left ">
                        Start Space Coworking Space
                      </p>
                      <p className="text-alternativeBlack leading-6 text-left">
                        Jl. Kemanggisan Raya No.64, RT.3/RW.7, Kemanggisan,{" "}
                        <br />
                        Kec. Palmerah, Kota Jakarta Barat, <br />
                        Daerah Khusus Ibukota Jakarta 11480
                      </p>
                      <button
                        className="flex flex-row gap-3 bg-white hover:bg-primaryOrange text-primaryOrange font-semibold hover:text-white py-2.5 px-5 border border-2 border-primaryOrange rounded-full"
                        onClick={handleClickOfflineCounselingMap}
                      >
                        <MapIconSolid className="w-5 h-5"></MapIconSolid>
                        Lihat Peta
                      </button>
                    </div>
                    <div className="flex flex-col justify-between items-start">
                      <p className="font-bold text-xl mb-4 text-alternativeBlack text-left">
                        Fasilitas
                      </p>
                      <div className="flex flex-col gap-2">
                        {facilities.map((facility: any, index: any) => (
                          <div key={index} className="flex items-center">
                            <div className="w-1/8">
                              <img
                                src={checkIcon}
                                className="w-6 h-6"
                                alt="check"
                              />
                            </div>
                            <p className="y-1 md:py-2 px-2.5 leading-5 text-alternativeBlack break-words">
                              {facility}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Panel>
      </Tab.Group>
      <Transition appear show={showTutorialVideoModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeTutorialVideoModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden w-full lg:w-1/2 rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Tutorial Konseling Offline KALM
                  </Dialog.Title>
                  <div className="mt-2 p-2 justify-center items-center mx-auto w-full">
                    <iframe
                      width="720"
                      height="405"
                      src="https://www.youtube.com/embed/D08h9UNI0pU"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full"
                    ></iframe>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeTutorialVideoModal}
                    >
                      Tutup
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Register;
