import React from 'react'
import ContactForm from '../components/ContactForm'
import contactImage from '../assets/contact.png'

const ContactUs = () => {
  return (
    <div className="mx-auto min-h-[62vh] text-primaryGrey">
      <div className="flex flex-col md:flex-row justify-evenly p-10">
        <div className="flex justify-center md:w-1/2">
          <img src={contactImage} alt="contact-us" />
        </div>

        <div className="flex flex-col md:w-1/2">
          <p className="text-2xl md:text-4xl mb-2.5 md:mb-5">Hubungi Kami</p>
          <p className="text-lg md:text-2xl my-2.5 md:my-5 font-light">Jika kamu punya pertanyaan seputar layanan kami, Silahkan kirimkan pertanyaan anda di sini.</p>
          <div className="p-5 md:p-10 border border-solid border-gray-300">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
