import React from 'react'
import { Question } from '../types/Question'
import faqImage from '../assets/faq.jpg'

const Questions = () => {
  const questions: Question[] = [
    {
      image: faqImage,
      q: 'Gimana cara daftar Chat konseling di KALM App?',
      a: 'Setelah kamu mengisi biodata di aplikasi KALM, kamu bisa meng-klik icon MinKa yang berada di bawah-tengah aplikasi. Setelah itu kamu akan memilih paket-paket konseling yang kamu ingin daftar.'
    },
    {
      image: faqImage,
      q: 'Kapan bisa Chat konseling sama Kalmselor?',
      a: 'Kamu bisa memulai konselingmu setelah kalmselor menyapamu melalui aplikasi'
    },
    {
      image: faqImage,
      q: 'Kapan chat konseling terhitung aktif?',
      a: 'Paket konseling baru dihitung berjalan ketika Kalmselor telah mengirimkan chat pertama kepada klien. Sehingga, Waktu menunggu terhubung dengan Kalmselor atau proses penggantian Kalmselor tidak dihitung sebagai hari aktif konseling.'
    },
    {
      image: faqImage,
      q: 'Kapan Kalmselor membalas chat saya?',
      a: 'Kalmselor memiliki jam aktif tersendiri sesuai yang tertulis di terms & condition (persetujuan awal Klien - Kalmselor). Namun, sistem konseling chat di aplikasi KALM merupakan on-going counseling, sehingga kamu tidak perlu menunggu Kalmselor untuk aktif, untuk bisa mengirimkan pesan. Selama paket aktif, silakan kirim pesan kapan pun kamu ingin dan Kalmselor akan membalas secara substansial minimal 2 momen dalam sehari, misalnya siang dan malam.'
    },
    {
      image: faqImage,
      q: 'Bagaimana cara memilih Kalmselor?',
      a: 'Kamu dapat melihat kode Kalmselor di Page Kalmselor Highlight Instagram kami. Setelah itu kamu bisa memasukan kode Kalmselornya dengan mengklik “ubah preferensi” dan memasukan kode kalmselor di kolom yang tertera.'
    }
  ]

  return (
    <div className="mx-auto text-primaryGrey">
      <div className="pt-5 md:pt-10 text-center">
        <p className="text-2xl md:text-4xl font-bold">Frequently Asked Questions</p>
        <p className="p-10 my-20 text-xl md:text-3xl font-light">Hai, KALMers! Buat kamu yang masih bingung cara menggunakan Aplikasi KALM, berikut adalah beberapa panduan yang akan memudahkan kamu melakukan konseling di Aplikasi KALM.</p>
      </div>

      <div className="flex justify-center mb-20">
        <iframe width="1120"
                height="630"
                src="https://www.youtube.com/embed/G6GaS9Rnwvc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </div>

      <div className="flex flex-col p-5 md:p-10">
        {questions.map((question, idx) => (
          <div key={idx} className="flex flex-col md:flex-row justify-evenly items-center p-2.5 md:p-5 my-2.5 md:my-5 odd:bg-primaryYellow even:bg-primaryGreen odd:text-primaryGrey even:text-white">
            <div className="flex justify-center w-full md:w-1/6 md:py-5">
              <img src={question.image} className="w-48 h-48" alt="faq-image" />
            </div>
            <div className="flex flex-col w-full md:w-3/4 p-2.5 md:p-5 md:mr-5">
              <p className="font-bold text-xl md:text-2xl mb-4">{question.q}</p>
              <p className="font-light text-lg md:text-xl mt-4">{question.a}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Questions
