import { useLocation, useNavigate } from "react-router-dom";
import { ResultLocationState } from "../types/Questionnaire";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation() as ResultLocationState;

  const handleClickTryAppButton = () =>
    (window.location.href = "https://bit.ly/KALMapp");
  const handleClickReadArticleButton = () => navigate(`/articles`);
  const handleClickKalmselorListButton = () => navigate(`/kalmselor-list`);

  //hardcoded for 404
  const code = "DOWNLOAD_APP";
  const scale = 4;

  //dynamically switch buttont title
  const buttonTitle = (code: string) => {
    if (code === "DOWNLOAD_APP") {
      return "Coba Aplikasi";
    }
    if (code === "KALMSELOR_LIST") {
      return "Lihat List Kalmselor";
    }
    if (code === "ARTICLE_LIST") {
      return "Baca Artikel";
    }
  };
  //dynamically switch button on click
  const onClickButton = (code: string) => {
    if (code === "DOWNLOAD_APP") {
      return handleClickTryAppButton;
    }
    if (code === "KALMSELOR_LIST") {
      return handleClickKalmselorListButton;
    }
    if (code === "ARTICLE_LIST") {
      return handleClickReadArticleButton;
    }
  };

  //dynamically switch minkas emoticon
  const minkaImage = (scale: string) => {
    const fileName = "minka-" + scale;
    return (
      <img
        src={require("../assets/minka/" + fileName + ".jpg")}
        className="object-fit w-48 h-48"
        alt={"minka"}
      />
    );
  };

  return (
    <div className="mx-auto text-primaryGrey">
      <div className="flex flex-col justify-center items-center py-5 md:py-10 bg-cover bg-[url('/src/assets/cloud-background.jpeg')]">
        <div className="w-11/12 lg:w-9/12">
          <div className="flex flex-col items-center py-10">
            <div className="flex">{minkaImage("4")}</div>
          </div>
          <h6 className="px-10 text-center text-9xl font-bold">404</h6>
          <div className="flex flex-col items-center px-10 py-5 md:py-10">
            <p className="my-2.5 text-lg md:text-2xl font-light text-center">
              Halaman tidak ditemukan
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center p-5 md:p-10 text-center bg-primaryOrange text-white">
        <div className="my-8 w-10/12 md:w-3/4">
          <p className="text-2xl md:text-4xl font-bold pb-2">
            Download aplikasi KALM di PlayStore atau AppStore untuk kebutuhan
            Kesehatan Mentalmu.
          </p>
        </div>
        <div className="flex flex-col items-center py-4">
          <div className="flex mb-4">
            <button
              className="bg-white hover:bg-primaryBlue text-lg md:text-2xl text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded cursor-pointer"
              onClick={onClickButton(code)}
            >
              {buttonTitle(code)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
