type FormValues = {
  code: string;
  answers: {
    question_id: number;
    answer_value: string;
  }[];
};

enum AnswerType {
  MUTIPLE_CHOICE = "MUTIPLE_CHOICE",
  RATE_4 = "RATE_4",
  RATE_5 = "RATE_5",
}

type Option = {
  id: number;
  question_value: string;
};

type Question = {
  id: number;
  page: number;
  question: string;
  question_type: AnswerType;
  options: Option[];
};

interface LocationState {
  state: {
    type: string;
  };
}

interface ResultLocationState {
    state: {
      title: string;
      description: string;
      emoticon_code: string;
      ctas: string[];
    };
  }

export { AnswerType };
export type { FormValues, Option, Question, LocationState, ResultLocationState };
