const config = {
  api: {
    getAllKalmselors: "/api/v1/kalmselors",
    getAllKalmselorsNoOffset: "/api/v1/allkalmselors",
    getKalmselorDetail: (code: string) =>
      `/api/v1/kalmselors?kalmselor_code=${code}`,
    getAllMajors: "/api/v1/majors",
    getAllExpertises: "/api/v1/expertises",
    getQuestionnaire: "/api/v1/questionnaire/questions",
    postQuestionnaire: "/api/v1/questionnaire/answer",
  },
  oldApi: {
    listArticles: "/api/v2/article",
    viewArticle: (articleId: string) => `/api/v2/article/${articleId}`,
  },
  resourceApi: {
    baseFileApiUrl: "https://v3.kalm-app.com/files/articles/",
  },
  pages: {
    home: "/",
    register: "/register",
    quiz: "/quiz",
    about: "/about-us",
    business: "/business",
    contact: "/contact-us",
    become: "/become-kalmselor",
    faq: "/faq",
    kalmselors: "/kalmselor-list",
    kalmselorDetail: "/kalmselor/:code",
    articles: "/articles",
    articleView: "/articles/:articleId",
    questionnaire: "/quiz/questionnaire/:type",
    questionnaireResult: "/quiz/questionnaireResult",
    oldRoute: "/id/*",
  },
  external: {
    playStore:
      "https://play.google.com/store/apps/details?id=id.cranium.kalm&gl=US",
    appStore:
      "https://apps.apple.com/id/app/kalm-online-counseling-more/id1440273816",
    facebook: "https://www.facebook.com/KALM.id",
    instagram: "https://www.instagram.com/get.kalm/",
    youtube: "https://www.youtube.com/channel/UC3jglcWjpJE4GtBuY7btnFw",
    twitter: "https://twitter.com/get_kalm",
    collaborate: "https://forms.gle/x4ag9nkTBtH2yofEA",
    poh: "https://www.peopleofhappiness.com/poh-x-kalm",
    quiz: {
      mentalHealth: "https://www.surveymonkey.com/r/2WBQV7T",
      stress: "https://www.surveymonkey.com/r/KALMlvlStress",
      burnOut: "https://www.surveymonkey.com/r/WHVSXZ5",
    },
    kalmselor: {
      playStore:
        "https://play.google.com/store/apps/details?id=id.cranium.counselor&gl=US",
      appStore:
        "https://apps.apple.com/id/app/kalmselor-kalm-counselors/id1440381889",
    },
    offlineCounseling: {
      zenwel: "https://widget.zenwel.com/554484974/kalm?lang=id&lid=4682",
      map: "https://g.page/startspace-x-founders-coworking?share",
      whatsapp:
        " https://wa.me/6281214007943?text=Hai%20saya%20ingin%20mengetahui%20informasi%20lebih%20lanjut%20mengenai%20konseling%20offline%20KALM",
      preCounselingForm: "https://bit.ly/konselingofflineKALM",
    },
  },
  contact: {
    business: "donny@get-kalm.com",
    support: "support@get-kalm.com",
  },
};

export default config;
