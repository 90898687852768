const baseUrl =
  process.env.REACT_APP_BASE_API_URI || "https://api.get-kalm.com";
const baseUrlOld =
  process.env.REACT_APP_BASE_API_URL_OLD || "https://v3.kalm-app.com";
const baseUrlOldImage =
  process.env.REACT_APP_BASE_API_URL_OLD || "https://v3.kalm-app.com/files/";
const baseUrlGolang =
  process.env.REACT_APP_BASE_API_URL_GOLANG || "https://api.get-kalm.com";

export function fetchApi(url: string) {
  return fetch(baseUrl + url).then((response) => response.json());
}

export function fetchApiOld(url: string) {
  return fetch(baseUrlOld + url, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  }).then((response) => {
    return response.json();
  });
}

export function fetchImageOldApi(path: string, imgName: string) {
  return fetch(baseUrlOldImage + path + imgName, {
    method: "get",
  }).then((response) => response.json());
}
export function fetchApiGolang(url: string) {
  return fetch(baseUrlGolang + url, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  }).then((response) => {
    return response.json();
  });
}

export function postApiGolang(url: string, data: any) {
  return fetch(baseUrlGolang + url, {
    method: "POST",
    body:JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}
