import React, { useState } from "react";

const SearchBar = ({ handleSearchItem }: any) => {
  const [item, setItem] = useState("");

  const handleOnChange = (e: any) => {
    setItem(e.target.value);
  };

  return (
    <div className="flex items-center">
      <label htmlFor="simple-search" className="sr-only">
        {item}
      </label>
      <div className="relative w-full">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5"
          placeholder="Search"
          onChange={(e) => handleOnChange(e)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSearchItem(item);
            }
          }}
        />
      </div>
      <button
        type="submit"
        className="p-2.5 ml-2 text-sm font-medium text-white bg-primaryGrey rounded-lg hover:bg-primaryBlue focus:outline-none"
        onClick={() => handleSearchItem(item)}
      >
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <span className="sr-only">Search</span>
      </button>
    </div>
  );
};

export default SearchBar;
