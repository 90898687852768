import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { fetchApi } from "../api";
import DropdownMenu from "../components/DropdownMenu";
import SearchBar from "../components/SearchBar";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Fuse from "fuse.js";
import {
  AcademicCapIcon,
  ArrowRightIcon,
  IdentificationIcon,
} from "@heroicons/react/outline";

const workingTypes = [
  {
    id: 1,
    title: "1 - 2 Tahun",
  },
  {
    id: 2,
    title: "3 - 7 Tahun",
  },
  {
    id: 3,
    title: "> 7 Tahun",
  },
];

const fuseOptions = {
  isCaseSensitive: false,
  minMatchCharLength: 3,
  keys: ["name", "kalmselor_code"],
};

const KalmselorList = () => {
  const navigate = useNavigate();
  const [allKalmselors, setAllKalmselors] = useState<any[]>([]);
  const [kalmselors, setKalmselors] = useState<any[]>([]);
  const [majors, setMajors] = useState<any[]>([]);
  const [selectedMajor, setSelectedMajor] = useState("");
  const [expertises, setExpertises] = useState<any[]>([]);
  const [selectedExpertise, setSelectedExpertise] = useState("");
  const [selectedWorkingType, setSelectedWorkingType] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Promise.all([
      getMajorsData(),
      getExpertisesData(),
      getAllKalmselors(),
    ]).finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (selectedName.length > 0) {
      const fuse = new Fuse(kalmselors, fuseOptions);
      const searchResult = fuse
        .search(selectedName)
        .map((result) => result.item);
      setKalmselors(searchResult);
    } else {
      setKalmselors(allKalmselors);
    }
  }, [selectedName]);

  useEffect(() => {
    let filteredKalmselors = kalmselors;
    if (selectedExpertise.length > 0) {
      filteredKalmselors = filteredKalmselors.filter((kalmselor) => {
        return kalmselor.expertise_list
          .toLowerCase()
          .includes(selectedExpertise.toLowerCase());
      });
    }

    if (selectedMajor.length > 0) {
      filteredKalmselors = filteredKalmselors.filter((kalmselor) => {
        return kalmselor.major_title
          .toLowerCase()
          .includes(selectedMajor.toLowerCase());
      });
    }
    setKalmselors(filteredKalmselors);
  }, [selectedExpertise, selectedMajor, selectedWorkingType]);

  const getAllKalmselors = () => {
    fetchApi(config.api.getAllKalmselorsNoOffset).then((response) => {
      setAllKalmselors(response.body);
      setKalmselors(response.body);
    });
  };

  const getMajorsData = () => {
    fetchApi(config.api.getAllMajors).then((response) => {
      const majorList = response.body.map((major: any) => ({
        id: major.major_id,
        title: major.title,
      }));
      setMajors(majorList);
    });
  };

  const getExpertisesData = () => {
    fetchApi(config.api.getAllExpertises).then((response) => {
      const expertiseList = response.body.map((expertise: any) => ({
        id: expertise.expertise_id,
        title: expertise.title,
      }));
      setExpertises(expertiseList);
    });
  };

  const handleClickLearnMoreButton = (code: string) =>
    navigate(`/kalmselor/${code}`);

  const handleClickSelectMajor = (major: any) => {
    const selected = major.title === selectedMajor ? "" : major.title;
    setSelectedMajor(selected);
  };

  const handleClickSelectExpertise = (expertise: any) => {
    const selected =
      expertise.title === selectedExpertise ? "" : expertise.title;
    setSelectedExpertise(selected);
  };

  const handleClickSelectWorkingType = (workingType: any) => {
    const selected =
      workingType.title === selectedWorkingType ? "" : workingType.title;
    setSelectedWorkingType(selected);
  };

  const handleClickSearch = (item: string) => {
    const selected = item === selectedName ? "" : item;
    setSelectedName(selected);
  };

  const handleClickResetFilter = () => {
    setSelectedMajor("");
    setSelectedExpertise("");
    setSelectedWorkingType("");
    setSelectedName("");
    setKalmselors(allKalmselors);
  };

  //pagination
  const itemsPerPage = 6;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = kalmselors.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(kalmselors.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % allKalmselors.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="mx-auto bg-neutral">
      <div className="flex flex-col items-center py-5 md:py-10">
        <div className="md:w-1/2 text-2xl md:text-4xl text-textAlternative font-bold text-center mb-4">
          Mengenal Kalmselor Lebih Dekat
        </div>
        <div className="md:w-1/2 text-xl text-textAlternative text-center">
          Semua konselor di KALM telah memiliki sertifikasi dan izin praktik
        </div>
      </div>

      <div className="w-3/4 md:w-1/2 mx-auto my-10">
        <SearchBar handleSearchItem={handleClickSearch} />
      </div>

      <div className="flex flex-col px-28 md:flex-row md:justify-start">
        <div className="flex justify-center md:justify-start">
          <div className="inline-flex mr-1 md:mr-5">
            <DropdownMenu
              title="Peminatan"
              items={majors}
              selectedItem={selectedMajor}
              handleSelectItem={handleClickSelectMajor}
            />
          </div>
          <div className="inline-flex mr-1 md:mr-5">
            <DropdownMenu
              title="Keahlian"
              items={expertises}
              selectedItem={selectedExpertise}
              handleSelectItem={handleClickSelectExpertise}
            />
          </div>
        </div>
        {(selectedMajor ||
          selectedExpertise ||
          selectedWorkingType ||
          selectedName) && (
          <div className="flex justify-center items-center mt-5 md:mt-0">
            <button
              className="bg-white hover:bg-primaryBlue w-auto text-md text-primaryBlue font-semibold hover:text-white py-1 px-2 md:px-4 border border-primaryBlue hover:border-transparent rounded"
              onClick={handleClickResetFilter}
            >
              Reset
            </button>
          </div>
        )}
      </div>

      {kalmselors.length > 0 && !isLoading ? (
        <div className="flex flex-col items-center p-5 md:p-28 md:py-10 gap-8">
          {currentItems.map((kalmselor) => (
            <div
              key={kalmselor.kalmselor_id}
              className="flex flex-col md:flex-row items-start py-2 px-4 md:p-10 bg-white border-2 border-neutralBorder border-solid rounded-xl"
            >
              <div className="flex flex-row my-4">
                <div className="flex">
                  <img
                    src={kalmselor.image_url}
                    className="w-32 h-32 md:w-44 md:h-44 object-cover object-top rounded-xl"
                    alt="kalmselor-profile"
                  />
                </div>

                <div className="flex flex-col md:hidden items-start p-3 gap-2">
                  <p className="text-2xl text-center text-primaryGrey font-semibold">
                    {kalmselor.name}
                  </p>
                  <div className="flex flex-row items-center">
                    <IdentificationIcon className="w-5 h-5 text-primaryBrown mr-2" />
                    <p className="text-sm text-center md:text-left text-primaryGrey font-medium">
                      {kalmselor.kalmselor_code}
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    <AcademicCapIcon className="w-5 h-5 text-primaryBrown mr-2" />
                    <p className="text-sm text-center md:text-left text-primaryGrey font-medium">
                      {kalmselor.major_title}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex-col md:ml-10">
                <div className="hidden md:flex flex-col gap-4">
                  <p className="text-2xl text-left text-primaryGrey font-semibold">
                    {kalmselor.name}
                  </p>
                  <div className="flex flex-col md:flex-row items-center md:gap-4">
                    <div className="flex flex-row items-center">
                      <IdentificationIcon className="w-6 h-6 text-primaryBrown mr-2" />
                      <p className="text-md text-left text-primaryGrey font-medium">
                        {kalmselor.kalmselor_code}
                      </p>
                    </div>
                    <div className="flex flex-row items-center">
                      <AcademicCapIcon className="w-6 h-6 text-primaryBrown mr-2" />
                      <p className="text-md text-left text-primaryGrey font-medium">
                        {kalmselor.major_title}
                      </p>
                    </div>
                  </div>
                </div>

                <p className="my-2 text-primaryGrey leading-5 text-justify">
                  {kalmselor.description}
                </p>
                <div className="flex justify-start">
                  <button
                    className="hover:underline w-auto pt-2 text-primaryBlue "
                    onClick={() =>
                      handleClickLearnMoreButton(kalmselor.kalmselor_code)
                    }
                  >
                    <div className="flex flex-row items-center">
                      <p className="my-2.5 text-left font-medium">
                        Selengkapnya
                      </p>
                      <ArrowRightIcon className="w-5 h-5 ml-2" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mb-3">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{itemOffset + 1}</span> to{" "}
                <span className="font-medium">{endOffset}</span> of{" "}
                <span className="font-medium">{kalmselors.length}</span> results
              </p>
            </div>
          </div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
            nextLabel={
              <>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </>
            }
            nextClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={
              <>
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </>
            }
            previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            pageLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
            activeLinkClassName="relative z-10 inline-flex items-center bg-primaryOrange hover:bg-primaryOrange px-4 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <div className="h-[500px] flex items-center justify-center">
          <div className="w-11/12 lg:w-9/12">
            <h4 className="px-10 text-center text-4xl font-bold">Oops</h4>
            <div className="flex flex-col items-center px-10 py-5">
              <p className="my-2.5 text-lg md:text-xl font-light text-center">
                Kalmselor tidak ditemukan
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KalmselorList;
