import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApi } from "../api";
import { useDeviceType } from "../context/DeviceTypeContext";
import config from "../config";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { AcademicCapIcon, IdentificationIcon } from "@heroicons/react/outline";

const KalmselorDetail = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const isMobile = useDeviceType();
  const [kalmselor, setKalmselor] = useState<any>(null);
  const [expertise, setExpertise] = useState([]);

  useEffect(() => {
    fetchApi(config.api.getKalmselorDetail(code ?? "")).then((response) => {
      const kalmselorDetail = response.body.kalmselors[0];
      setKalmselor(kalmselorDetail);
      const expertiseResponse = kalmselorDetail.expertise_list.replace(
        /\\u0026/g,
        "&"
      );
      setExpertise(expertiseResponse.split(";"));
    });
  }, []);

  const handleClickBackButton = () => navigate(config.pages.kalmselors);

  const getVideoUrl = (url: string) => {
    const prefix = "https://www.youtube.com/watch?v=";
    const baseEmbedUrl = "https://www.youtube.com/embed/";

    return url.replace(prefix, baseEmbedUrl).split("&")[0];
  };

  return (
    <div className="mx-auto bg-neutral px-5 lg:px-28 ">
      <div className="flex flex-col">
        <div className="flex justify-between items-center mt-14 text-4xl text-primaryGrey font-bold text-center">
          <div className="flex justify-start w-1/12">
            <ArrowLeftIcon
              className="w-7 h-7 cursor-pointer"
              onClick={handleClickBackButton}
            />
          </div>
          <div className="w-11/12 text-textAlternative">
            <p>Profil Kalmselor</p>
            <div className="text-lg font-medium mt-6">
              Berkenalan lebih dekat dengan Kalmselor KALM
            </div>
          </div>
        </div>

        {kalmselor && (
          <>
            <div className="flex flex-col my-14 p-5 lg:p-10 bg-white border-2 border-neutralBorder border-solid rounded-xl">
              <div className="flex flex-col lg:flex-row items-start">
                <div className="flex flex-row">
                  <div className="flex">
                    <img
                      src={kalmselor.image_url}
                      className="w-48 h-48 lg:w-96 lg:h-96 object-cover object-top rounded-xl mb-6"
                      alt="kalmselor-profile"
                    />
                    <div className="flex flex-col lg:hidden items-start p-3 gap-2">
                      <p className="text-2xl text-center text-primaryGrey font-semibold">
                        {kalmselor.name}
                      </p>
                      <div className="flex flex-row items-center">
                        <IdentificationIcon className="w-5 h-5 text-primaryBrown mr-2" />
                        <p className="text-sm text-center md:text-left text-primaryGrey font-medium">
                          {kalmselor.kalmselor_code}
                        </p>
                      </div>
                      <div className="flex flex-row items-center">
                        <AcademicCapIcon className="w-5 h-5 text-primaryBrown mr-2" />
                        <p className="text-sm text-center md:text-left text-primaryGrey font-medium">
                          {kalmselor.major_title}
                        </p>
                      </div>
                      <div className="flex flex-col gap-2 mt-2 text-sm ">
                        <div className="font-semibold">Domisili</div>
                        <p>{kalmselor.address}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-1 flex-col pl-5 lg:pl-8 text-textAlternative">
                  <div className="hidden lg:flex flex-col gap-4">
                    <p className="text-3xl font-semibold">{kalmselor.name}</p>
                    <div className="flex flex-col xl:flex-row items-start md:gap-4">
                      <div className="flex flex-row items-center">
                        <IdentificationIcon className="w-7 h-7 text-primaryBrown mr-2" />
                        <p className="text-lg text-center md:text-left font-medium">
                          {kalmselor.kalmselor_code}
                        </p>
                      </div>
                      <div className="flex flex-row items-center">
                        <AcademicCapIcon className="w-7 h-7 text-primaryBrown mr-2" />
                        <p className="text-lg text-center md:text-left font-medium">
                          {kalmselor.major_title}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mb-4">
                      <div className="text-lg font-semibold">Domisili</div>
                      <p>{kalmselor.address}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mb-4">
                    <div className="text-lg font-semibold">Keahlian</div>
                    <p className="leading-6">
                      {expertise.map((exp: any, index) => {
                        return (
                          <React.Fragment key={index}>
                            {exp}
                            {index < expertise.length - 1 ? "," : ""}
                          </React.Fragment>
                        );
                      })}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="text-lg font-semibold">Bio</div>
                    <p className="leading-6"> {kalmselor.description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {kalmselor.video_url && (
                <div className="flex flex-col md:flex-row gap-5 items-center text-textAlternative justify-end mb-20">
                  <div className="flex flex-col w-1/2 px-5 items-center">
                    <div className="w-96 text-center lg:text-start">
                      <p className="text-2xl md:text-3xl font-bold">
                        Kata Kalmselor of the Month
                      </p>
                      <p className="leading-6">
                        Tonton apa kata Kalmselor tentang topik-topik seputar
                        psikologi
                      </p>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 justify-end items-end">
                    <div className="aspect-w-16 aspect-h-9">
                      <iframe
                        className="w-full aspect-video rounded-2xl"
                        src={getVideoUrl(kalmselor.video_url)}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KalmselorDetail;
