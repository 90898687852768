import React, { useState, useContext, useEffect } from 'react'

const DeviceTypeContext = React.createContext(false)
const DeviceTypeUpdateContext = React.createContext(() => {})

const useDeviceType = () => useContext(DeviceTypeContext)

const DeviceTypeProvider = (props: any) => {
  const [isMobile, setIsMobile ] = useState(false)

  const toggleDeviceType = () => {
    if (document.body.clientWidth < 768) {
      return setIsMobile(true)
    }
    return setIsMobile(false)
  }

  useEffect(() => {
    toggleDeviceType()
  }, [document.body.clientWidth])

  return (
    <DeviceTypeContext.Provider value={isMobile}>
      <DeviceTypeUpdateContext.Provider value={toggleDeviceType}>
        {props.children}
      </DeviceTypeUpdateContext.Provider>
    </DeviceTypeContext.Provider>
  )
}

export {
  useDeviceType,
  DeviceTypeProvider
}
