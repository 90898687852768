import React from "react";
import config from "../config";
import { Program } from "../types/Program";
import { Reason } from "../types/Reason";
import { Testimonial } from "../types/Testimonial";
import heroImage from "../assets/business-hero.png";
import firstReason from "../assets/business-reason-1.png";
import secondReason from "../assets/business-reason-2.png";
import thirdReason from "../assets/business-reason-3.png";
import firstProgram from "../assets/program-1.png";
import secondProgram from "../assets/program-2.png";
import thirdProgram from "../assets/program-3.png";
import fourthProgram from "../assets/program-4.png";
import fifthProgram from "../assets/program-5.png";
import sixthProgram from "../assets/program-6.png";
import maybellineLogo from "../assets/client/maybelline.png";
import sampoernaLogo from "../assets/client/sampoerna.png";
import pertaminaLogo from "../assets/client/pertamina.png";
import dilmahLogo from "../assets/client/dilmah.png";
import cermatiLogo from "../assets/client/cermati.png";
import goWorkLogo from "../assets/client/gowork.png";
import populixLogo from "../assets/client/populix.png";
import echoLogo from "../assets/client/echo.png";
import bpjsLogo from "../assets/client/bpjs.png";
import katadataLogo from "../assets/media/katadata.jpeg";
import metroTvLogo from "../assets/media/metrotv.jpeg";
import idnTimesLogo from "../assets/media/idntimes.jpeg";

const KalmForBusiness = () => {
  const reasons: Reason[] = [
    {
      image: firstReason,
      text: "Menjadi one-stop-solution untuk kesehatan mental karyawan perusahaanmu",
    },
    {
      image: secondReason,
      text: "Konseling dapat disesuaikan berdasarkan  jadwal kerja dan kebutuhan karyawan",
    },
    {
      image: thirdReason,
      text: "Kegiatan dapat disesuaikan dengan kebutuhan perusahaan",
    },
  ];

  const businesses = [
    { image: maybellineLogo },
    { image: sampoernaLogo },
    { image: pertaminaLogo },
    { image: dilmahLogo },
    { image: cermatiLogo },
    { image: goWorkLogo },
    { image: populixLogo },
    { image: echoLogo },
    { image: bpjsLogo },
  ];

  const medias = [
    { image: katadataLogo },
    { image: metroTvLogo },
    { image: idnTimesLogo },
  ];

  const testimonials: Testimonial[] = [
    {
      description:
        "Untuk acaranya bagus, bawainnya juga enak dan bersemangat, membuat peserta bertahan lama mengikuti acara, contoh-contoh yang disajikan mudah untuk dimengerti.",
      author: "Steffie Khessia, Culture & Engagement TADA",
    },
    {
      description:
        "Kami menggunakan layanan Office Counseling dan KALM membantu karyawan saya menyadari dan mengelola stres & burnout di keadaan 2020 yang sedang sulit ini.",
      author: "Astrid Sugandi, Founder of Aaska",
    },
    {
      description: "Thank you materinya cocok sekali dengan kebutuhan tim kita",
      author: "Vania, Komisaris PT. VOSEN PRATITA KEMINDO",
    },
    {
      description: `It's great dari proses awal sampai akhir acara`,
      author: "Valeria, Marketing & Communication Guèle",
    },
    {
      description:
        "Semua rangkaian acara yang dijalankan dengan KALM baik; materi yang dipersiapkan holistik, mendalam dan menjawab bukan hanya kulit tapi sampai ke intisari permasalahan dan memberikan rekomendasi yang praktis",
      author: "Rachel, Senior People Partner OVO",
    },
    {
      description:
        "Stres yang disebabkan pandemi, seringkali menimbulkan kesepian. Bersama KALM di sesi acara mingguan @Tokopedia, mengingatkan kita bahwa kita semua menghadapi ini bersama dan semakin pentingnya menjaga kesehatan mental",
      author: "Aninditia, Corporate Communications Tokopedia",
    },
  ];

  const programs: Program[] = [
    {
      image: firstProgram,
      title: "Chat Counseling",
      description:
        "Konsultasi tanpa batas dengan Kalmselor, menggunakan sistem On-going chat kami di Aplikasi",
    },
    {
      image: secondProgram,
      title: "Video Counseling",
      description:
        "1 sesi Video Counseling dengan Kalmselor per karyawan dalam 1 tahun",
    },
    {
      image: thirdProgram,
      title: "KALM Webinar",
      description: "1 KALM Webinar per tahun yang ditagih tiap tahunnya",
    },
    {
      image: fourthProgram,
      title: "Self-care Activities",
      description:
        "Aktifitas yang dapat dilakukan secara mandiri untuk meningkatkan Self-care seperti Gratitude Journal, well-being tracker dan meditasi",
    },
    {
      image: fifthProgram,
      title: "Mental Health Assessment",
      description:
        "Assessment kami dapat membantu perusahaan mengidentifikasi dan mengambil langkah selanjutnya untuk memiliki kemajuan kesehatan mental seluruh karyawan",
    },
    {
      image: sixthProgram,
      title: "Mental Wellness Articles",
      description:
        "Petunjuk dan sumber daya tentang mental health yang mudah dicerna dan dapat diaplikasikan kedalam keseharianmu",
    },
  ];

  const handleClickCollaborate = () =>
    (window.location.href = config.external.collaborate);

  return (
    <div className="mx-auto text-primaryGrey">
      {/*Hero Section*/}
      <div className="flex flex-col md:flex-row justify-evenly items-center p-5 md:p-10">
        <div className="object-fit">
          <img src={heroImage} alt="hero-image" />
        </div>

        <div className="flex flex-col p-4 md:w-1/2 text-left">
          <p className="py-3 text-2xl md:text-4xl font-bold">
            KALM for Your Business!
          </p>
          <p className="my-2.5 text-lg md:text-2xl leading-6 text-gray-600">
            70% orang mengalami stres terkait pekerjaannya, dan 40% orang
            terancam kesehatannya karena terlalu banyak bekerja (overwork).
          </p>
          <p className="my-2.5 text-lg md:text-2xl leading-6 text-gray-600">
            Banyak karyawan yang produktivitas dan kinerjanya menurun karena
            kondisi kesehatan mental mereka yang menurun.
          </p>
          <p className="my-2.5 text-lg md:text-2xl leading-6 text-gray-600">
            Bersama KALM, mari kita tingkatkan kesehatan mental karyawan dan
            diri agar semua dapat mencapai titik optimal untuk menjalani hari.
          </p>
          <p className="my-2.5 text-lg md:text-2xl leading-6 text-gray-600">
            Happier employees = Better companies.
          </p>
        </div>
      </div>

      {/*Reason Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10 bg-primaryOrange">
        <p className="pb-10 text-2xl md:text-4xl font-bold text-center text-white">
          Kenapa harus bekerja sama dengan KALM?
        </p>
        <div className="flex flex-wrap lg:flex-row justify-center">
          {reasons.map((reason, index) => (
            <div
              key={index}
              className="flex flex-col max-w-xs rounded overflow-hidden shadow-lg my-3 md:mx-5 bg-[#EEE]"
            >
              <img
                className="w-full h-3/4"
                src={reason.image}
                alt="reason-image"
              />
              <div className="flex items-center h-1/4 px-6 py-4 bg-white">
                <p className="text-gray-600 text-xl text-center">
                  {reason.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*Testimony Section*/}
      <div className="flex flex-col p-5 md:p-10">
        <div className="flex flex-col justify-center items-center mb-5">
          <div className="pb-10">
            <p className="text-2xl md:text-4xl leading-10 text-center font-bold">
              Mereka Yang Telah Terbantu oleh KALM
            </p>
          </div>
          <div className="flex flex-wrap justify-center items-center md:w-2/3">
            {businesses.map((business, index) => (
              <div
                key={index}
                className="flex justify-center items-center md:w-1/3 p-6"
              >
                <img src={business.image} alt="play-store" />
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col justify-center items-center mt-5">
          <div className="pb-10">
            <p className="text-2xl md:text-4xl text-center">
              Telah diliput oleh
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center md:w-2/3">
            {medias.map((media, index) => (
              <div
                key={index}
                className="flex justify-center items-center md:w-1/4 p-3"
              >
                <img
                  src={media.image}
                  className="object-contain"
                  alt="play-store"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center py-10">
          <button
            className="bg-transparent hover:bg-primaryBlue text-lg md:text-2xl text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
            onClick={handleClickCollaborate}
          >
            Bekerja sama dengan kami
          </button>
        </div>
      </div>

      {/*Review Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10">
        <p className="text-2xl md:text-4xl font-bold pb-3.5 md:pb-7">
          Kata mereka yang sudah berproses bersama KALM...
        </p>
        <div className="flex flex-wrap justify-center">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col justify-evenly md:w-1/4 p-4 m-4 text-lg leading-6 font-light bg-gray-300"
            >
              <p className="mb-2.5 font-bold">{testimonial.description}</p>
              <p className="mt-2.5">- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>

      {/*Program Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10">
        <p className="text-2xl md:text-4xl font-bold pb-3.5 md:pb-7">
          Program yang kami sediakan
        </p>
        <div className="flex flex-wrap justify-center">
          {programs.map((program, index) => (
            <div key={index} className="flex justify-center md:w-1/2">
              <div className="flex flex-col justify-between max-w-lg rounded overflow-hidden shadow-lg m-3">
                <div className="md:h-1/2 lg:h-5/6">
                  <img
                    className="w-full"
                    src={program.image}
                    alt="program-image"
                  />
                </div>
                <div className="md:h-1/2 lg:h-2/6 px-6 py-4 bg-gray-200">
                  <p className="font-bold text-center text-xl mb-2">
                    {program.title}
                  </p>
                  <p className="text-gray-700 text-lg text-center">
                    {program.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*End Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10 text-center bg-primaryOrange text-white">
        <div className="my-8">
          <p className="text-2xl md:text-4xl font-bold pb-2">
            Tunggu apa lagi? Mari bekerja sama dengan KALM untuk
          </p>
          <p className="text-2xl md:text-4xl font-bold pb-2">
            Karyawan yang lebih bahagia dan Perusahaan yang lebih baik!
          </p>
        </div>
        <div className="my-8">
          <p className="text-2xl md:text-4xl font-bold pb-2">
            Investasi mulai dari
          </p>
          <p className="text-2xl md:text-4xl font-bold pb-2">
            Rp 89.000/ Karyawan*
          </p>
        </div>
        <div className="flex justify-center py-5 md:py-10">
          <a
            href={`mailto:${config.contact.business}`}
            className="bg-white hover:bg-primaryBlue text-lg md:text-2xl text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded cursor-pointer"
          >
            Hubungi kami di sini!
          </a>
        </div>
      </div>
    </div>
  );
};

export default KalmForBusiness;
