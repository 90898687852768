import React from "react";
import { useNavigate } from "react-router-dom";
// import config from "../config";
import firstQuiz from "../assets/quiz-1.png";
import secondQuiz from "../assets/quiz-2.png";
import thirdQuiz from "../assets/quiz-3.png";

const KalmQuiz = () => {
  const navigate = useNavigate();

  //* Old code contain surveymonkey forms
  // const { mentalHealth, stress, burnOut } = config.external.quiz;
  // const handleClickCheckMentalHealthScore = () =>
  //   (window.location.href = mentalHealth);
  // const handleClickCheckStressLevelScore = () =>
  //   (window.location.href = stress);
  // const handleClickCheckBurnOut = () => (window.location.href = burnOut);

  const handleClickQuestionnaire = (type: string) =>
    navigate(`/quiz/questionnaire/${type}`, {
      state: { type: type },
    });

  return (
    <div className="mx-auto text-primaryGrey">
      {/*Mental Health Score Section*/}
      <div className="flex flex-col md:flex-row justify-evenly items-center p-5 md:p-10">
        <div className="object-fit">
          <img src={firstQuiz} alt="mental-health" />
        </div>

        <div className="flex flex-col p-4 md:w-1/2 text-left">
          <p className="py-5 text-2xl md:text-4xl font-bold">
            Berapa skor kesehatan mentalmu?
          </p>
          <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
            Kamu belum yakin perlu bantuan profesional atau tidak? Yuk, cari
            tahu kondisi kesehatan mentalmu saat ini. Hanya dengan menjawab 10
            pertanyaan singkat di quiz ini, kamu dapat menemukan gambaran
            tentang kondisimu saat ini dan mendapatkan saran praktis, lho!
          </p>
          <div className="flex py-10">
            <button
              className="bg-transparent hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              onClick={() => handleClickQuestionnaire("MENTAL_HEALTH")}
            >
              Cek di sini!
            </button>
          </div>
        </div>
      </div>

      {/*Stress Level Section*/}
      <div className="flex flex-col md:flex-row-reverse justify-evenly items-center p-5 md:p-10">
        <div className="object-contain">
          <img src={secondQuiz} alt="stress-level" />
        </div>

        <div className="flex flex-col p-4 md:w-1/2 text-left">
          <p className="py-5 text-2xl md:text-4xl font-bold">
            Berapa tingkat stressmu?
          </p>
          <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
            Ketauhi tingkat stressmu dalam 1 minggu ini dengan mengisi quiz.
          </p>
          <div className="flex py-10">
            <button
              className="bg-transparent hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              onClick={() => handleClickQuestionnaire("STRESS")}
            >
              Cek di sini!
            </button>
          </div>
        </div>
      </div>

      {/*Burn Out Section*/}
      <div className="flex flex-col md:flex-row justify-evenly items-center p-5 md:p-10">
        <div className="object-fit">
          <img src={thirdQuiz} alt="burn-out" />
        </div>

        <div className="flex flex-col p-4 md:w-1/2 text-left">
          <p className="py-5 text-2xl md:text-4xl font-bold">
            Apakah aku sedang Burnout?
          </p>
          <p className="my-5 text-lg md:text-2xl leading-6 text-gray-600">
            Bak mesin yang terus dipaksa bekerja hingga akhirnya panas dan
            akhirnya malah tidak bisa berfungsi lagi, itulah fisik & mental kita
            ketika mengalami BURNOUT. Burnout bisa mengakibatkan penyakit mental
            dan fisik, lho! Lebih baik dipastiin aja BERAPA PERSEN kemungkinan
            kamu sedang mengalami BURNOUT.
          </p>
          <div className="flex py-10">
            <button
              className="bg-transparent hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              onClick={() => handleClickQuestionnaire("BURNED_OUT")}
            >
              Cek di sini!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KalmQuiz;
