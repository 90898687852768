import React from "react";
import config from "../config";
import { Reason } from "../types/Reason";
import logo from "../assets/logo-dark.png";
import heroImage from "../assets/become-hero.png";
import firstReason from "../assets/become-1.png";
import secondReason from "../assets/become-2.png";
import thirdReason from "../assets/become-3.png";
import fourthReason from "../assets/become-4.png";
import playStoreLogo from "../assets/play-store.png";
import appStoreLogo from "../assets/app-store.png";

const BecomeKalmselor = () => {

  const reasons: Reason[] = [
    {
      image: firstReason,
      text: "Fleksibel secara waktu dalam melayani Klien",
    },
    {
      image: secondReason,
      text: "Jangkauan klien dan jejaring yang lebih luas",
    },
    {
      image: thirdReason,
      text: "Tingkatkan pendapatan dan penghasilan",
    },
    {
      image: fourthReason,
      text: "Punya waktu utk merefleksikan masalah klien & menyusun respon terbaik utk klien.",
    },
  ];

  const handleClickPlayStore = () =>
    (window.location.href = config.external.kalmselor.playStore);
  const handleClickAppStore = () =>
    (window.location.href = config.external.kalmselor.appStore);

  return (
    <div className="flex flex-col items-center mx-auto text-primaryGrey">
      {/*Hero Section*/}
      <div className="flex flex-col md:flex-row justify-center items-center p-5 md:p-10">
        <div className="md:w-1/2">
          <div className="flex flex-col p-4 text-left">
            <p className="py-2 text-2xl md:text-4xl font-bold">
              Bergabung jadi Kalmselor!
            </p>
            <p className="text-lg md:text-2xl leading-6 text-gray-600">
              Jika anda seorang konselor, psikolog, atau psikiater yang ingin
              memberikan impact yang baik orang lain. KALM hadir untuk membantu
              Anda.{" "}
            </p>
          </div>
        </div>

        <div className="md:w-1/5">
          <div className="object-fit">
            <img src={heroImage} alt="hero" />
          </div>
        </div>
      </div>

      {/*Reason Section*/}
      <div className="flex flex-col justify-center items-center w-screen p-5 md:p-10 bg-primaryBlue">
        <p className="pb-10 text-2xl md:text-4xl text-white text-center font-bold">
          Kenapa harus bergabung dengan KALM?
        </p>
        <div className="flex flex-wrap lg:flex-row justify-center">
          {reasons.map((reason, index) => (
            <div
              key={index}
              className="max-w-xs rounded overflow-hidden shadow-lg my-3 md:mx-2 bg-primaryYellow"
            >
              <img
                className="w-full bg-white h-2/3"
                src={reason.image}
                alt="reason"
              />
              <div className="flex items-center px-6 py-4 h-1/3">
                <p className="text-gray-600 text-lg text-center">
                  {reason.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*T&C Section*/}
      <div className="flex flex-col lg:flex-row items-center lg:items-start lg:w-11/12 p-5 lg:p-10">
        <div className="flex flex-col w-10/12 lg:w-1/2 px-6 mb-4 lg:mb-0">
          <div className="pb-4">
            <p className="text-center text-2xl lg:text-4xl font-bold">
              Syarat dan Ketentuan
            </p>
          </div>
          <ol className="text-gray-600 list-decimal">
            <li className="py-2 text-lg lg:text-2xl">
              Memiliki paling tidak ijazah S2 konseling, psikologi, dan/atau
              psikiatri atau memiliki gelar psikolog.
            </li>
            <li className="py-2 text-lg lg:text-2xl">
              Memiliki sedikitnya 200 jam pengalaman konseling tatap muka
            </li>
            <li className="py-2 text-lg lg:text-2xl">
              Memiliki smartphone dan akses ke Internet yang bisa diandalkan.
            </li>
          </ol>
        </div>

        <div className="flex flex-col w-10/12 lg:w-1/2 px-6 mt-4 lg:mt-0">
          <div className="pb-4">
            <p className="text-center text-2xl lg:text-4xl font-bold">
              Dokumen yang diperlukan
            </p>
          </div>
          <ol className="text-gray-600 list-decimal">
            <li className="py-2 text-lg lg:text-2xl">
              Untuk dapat menjadi seorang Kalmselor, Anda akan diminta untuk
              mengunggah dokumen-dokumen dibawah ini di dalam Aplikasi Kalmselor
              kami:
            </li>
            <li className="py-2 text-lg lg:text-2xl">KTP/Passport</li>
            <li className="py-2 text-lg lg:text-2xl">Ijazah S1</li>
            <li className="py-2 text-lg lg:text-2xl">Ijazah S2</li>
            <li className="py-2 text-lg lg:text-2xl">Surat Ijin Praktek</li>
            <li className="py-2 text-lg lg:text-2xl">
              SKCK apabila tidak memiliki Surat Ijin Praktek
            </li>
            <li className="py-2 text-lg lg:text-2xl">NPWP</li>
          </ol>
        </div>
      </div>

      {/*Invitation Section*/}
      <div className="flex flex-col justify-center items-center w-full p-5 md:p-10 bg-cover bg-[url('/src/assets/cloud-background.jpeg')]">
        <div className="md:w-1/2 text-center">
          <p className="text-2xl md:text-4xl font-bold">
            Bergabunglah dengan KALM dan Download Aplikasi Kalmselor sekarang!
          </p>
        </div>
        <div className="object-fit w-32 h-32 my-10">
          <img src={logo} alt="logo" />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <img
            src={playStoreLogo}
            alt="play-store"
            className="object-contain w-24 md:w-36 h-10 md:h-12 mx-5 cursor-pointer"
            onClick={handleClickPlayStore}
          />
          <img
            src={appStoreLogo}
            alt="app-store"
            className="object-contain w-24 md:w-36 h-10 md:h-12 mx-5 cursor-pointer"
            onClick={handleClickAppStore}
          />
        </div>
      </div>

      {/*Tutorial Section*/}
      <div className="flex flex-col justify-center items-center w-full p-5 md:p-10">
        <div className="md:w-1/2 py-8 text-center">
          <p className="text-2xl md:text-4xl font-bold">
            Tutorial Kalmselor App
          </p>
        </div>
      </div>
      <div className="w-10/12 lg:w-1/2 bg-black mb-20">
        <iframe
          className="w-full aspect-video "
          src="https://www.youtube.com/embed/kHqQdKzvnuQ"
          frameBorder="0"
          title="Tutorial Kalmselor App"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default BecomeKalmselor;
