import React from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import config from "../config";
import { Reason } from "../types/Reason";
import { Testimonial } from "../types/Testimonial";
import articleImage from "../assets/landing-article.jpeg";
import firstReason from "../assets/about-reason-1.png";
import secondReason from "../assets/about-reason-2.jpeg";
import thirdReason from "../assets/about-reason-3.png";
import maybellineLogo from "../assets/client/maybelline.png";
import sampoernaLogo from "../assets/client/sampoerna.png";
import pertaminaLogo from "../assets/client/pertamina.png";
import dilmahLogo from "../assets/client/dilmah.png";
import cermatiLogo from "../assets/client/cermati.png";
import goWorkLogo from "../assets/client/gowork.png";
import populixLogo from "../assets/client/populix.png";
import echoLogo from "../assets/client/echo.png";
import bpjsLogo from "../assets/client/bpjs.png";
import firstBanner from "../assets/landing/banner-1.png";
import secondBanner from "../assets/landing/banner-2.png";
import thirdBanner from "../assets/landing/banner-3.png";

const reasons: Reason[] = [
  {
    image: firstReason,
    text: "Bisa didampingi dengan psikolog selama beberapa hari sekaligus",
  },
  {
    image: secondReason,
    text: "Didampingi oleh Kalmselor yang memiliki sertifikasi dan izin praktik",
  },
  {
    image: thirdReason,
    text: "Harga konseling yang terjangkau",
  },
];

const testimonials: Testimonial[] = [
  {
    description:
        '"Thank you! Tdk hanya konselor yg bikin tenang, design dan grafik di Kalm menenangkan bgt hahaha"',
    author:
        "Nur, Chat Konseling 3 Hari dengan Kalmselor Yudha Heka (YUD-359)",
  },
  {
    description:
        '"1 Hour is not enough, thank you for thoughtful discussion In 50min"',
    author:
        "Krishanto, Video Konseling dengan Kalmselor Reisha Hermana (REI-781)",
  },
  {
    description:
        '"Terimakasih kak Maya. Walau saya ambil paket konseling singkat sekitar 3 hari tapi saya dapat menemukan pencerahan tanpa merasa dihakimi. Apa yang saya ceritakan selalu diberi feedback yang membuat saya merasa dimengerti.',
    author:
        "Azizah, Chat Konseling 3 Hari dengan Kalmselor Maya Dwiayuningtiyas (MAY-928)",
  },
  {
    description:
        "Terima kasih kak, terima kasih sudah menyadarkan aku kalo selama ini aku hanya lupa untuk menamakan emosi dan salah sasaran untuk menyalurkan, terima kasih saran baiknya :)",
    author: "Putri, Video Konseling dengan Kalmselor Cut Maghfirah (CUT-706)",
  },
  {
    description:
        '"Terima kasih telah jadi salah satu hal yang aku syukuri saat ini kak. Terima kasih banyak insightnya kak 🍀🍀"',
    author:
        "Nadia, Chat Konseling 3 Hari dengan Kalmselor Tiffany Chandra (TIFF-799)",
  },
  {
    description:
        '"Terimakasih karena telah memberikan saya sedikit cahaya ditengah hidup saya yang terasa gelap ini"',
    author:
        "Anisa, Chat Konseling 3 Hari dengan Kamselor Larasati Margaretha (LAR-605)",
  },
];

const businesses = [
  { image: maybellineLogo },
  { image: sampoernaLogo },
  { image: pertaminaLogo },
  { image: dilmahLogo },
  { image: cermatiLogo },
  { image: goWorkLogo },
  { image: populixLogo },
  { image: echoLogo },
  { image: bpjsLogo },
];

// TODO: this is temporary, replace with actual data from API call
const banners = [
  // { image: bannerZero, page_url: "#" },
  { image: firstBanner, page_url: config.pages.register },
  {
    image: secondBanner,
    page_url: config.external.poh,
  },
  { image: thirdBanner, page_url: config.pages.business },
];

const Home = () => {
  const navigate = useNavigate();

  const handleCarouselClick = (page_url: string) => {
    if (page_url.startsWith("http")) {
      window.open(page_url, "_blank");
    } else {
      navigate(page_url);
    }
  };

  const handleClickAboutUsButton = () => navigate(config.pages.about);

  const handleClickListKalmselorButton = () =>
    navigate(config.pages.kalmselors);

  const handleClickRegisterButton = () => navigate(config.pages.register);

  const handleClickArticleButton = () => navigate(config.pages.articles);

  const handleClickCollaborate = () =>
    (window.location.href = config.external.collaborate);

  return (
    <div className="mx-auto text-primaryGrey">
      <div className="flex justify-center items-center">
        <Carousel
          infiniteLoop={true}
          showStatus={false}
          autoPlay={true}
          interval={5000}
          dynamicHeight={true}
          showThumbs={false}
          emulateTouch={true}
          onClickItem={(index, item) =>
            handleCarouselClick(banners[index].page_url)
          }
          showArrows={false}
          className="w-full h-full cursor-pointer"
        >
          {banners.map((banner, index) => (
            <div key={index}>
              <img
                src={banner.image}
                key={index}
                className="object-contain"
                alt="banner-image"
              />
            </div>
          ))}
        </Carousel>
      </div>

      {/*About Section*/}
      <div className="flex flex-col-reverse md:flex-row items-center p-5 md:p-10">
        <div className="flex justify-center w-1/2 mx-5">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/rX87q05OSFk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="flex flex-col items-center md:items-start md:w-1/2 mx-5 text-center md:text-left">
          <p className="text-4xl font-bold mb-5">Tentang KALM</p>
          <p className="mt-2.5 mb-1 text-xl font-light">
            Ingin tahu lebih lanjut mengenai KALM dan Kalmselor kami?
          </p>
          <p className="mb-2.5 mt-1 text-xl font-light">
            Silahkan klik di bawah ini!
          </p>

          <div className="mt-2.5 mb-1">
            <button
              className="bg-white hover:bg-primaryBlue w-auto text-lg md:text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              onClick={handleClickAboutUsButton}
            >
              Baca Kisah Kami
            </button>
          </div>
          <div className="mb-2.5 mt-1">
            <button
              className="bg-white hover:bg-primaryBlue w-auto text-lg md:text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              onClick={handleClickListKalmselorButton}
            >
              Lihat Daftar Kalmselor
            </button>
          </div>
        </div>
      </div>

      {/*Reason Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10 bg-primaryBlue">
        <p className="pb-10 text-2xl md:text-4xl text-white text-center font-bold">
          Kenapa harus konseling di KALM?
        </p>
        <div className="flex flex-col md:flex-row">
          {reasons.map((reason, index) => (
            <div
              key={index}
              className="max-w-xs rounded overflow-hidden shadow-lg my-3 md:mx-5 bg-primaryYellow"
            >
              <img
                className="w-full bg-white h-2/3"
                src={reason.image}
                alt="reason-image"
              />
              <div className="flex justify-center items-center px-6 py-4 h-1/3">
                <p className="text-gray-600 text-lg text-center">
                  {reason.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*Review Section*/}
      <div className="flex flex-col justify-center items-center p-5 md:p-10">
        <p className="text-2xl md:text-4xl font-bold pb-3.5 md:pb-7">
          Apa kata mereka yang sudah menggunakan KALM...
        </p>
        <div className="flex flex-wrap justify-center">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col justify-evenly md:w-1/4 p-4 m-4 text-lg leading-6 font-light bg-gray-300"
            >
              <p className="mb-2.5 font-bold">{testimonial.description}</p>
              <p className="mt-2.5">- {testimonial.author}</p>
            </div>
          ))}
        </div>
        <div className="mb-2.5 mt-1">
          <button
            className="bg-white hover:bg-primaryBlue text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
            onClick={handleClickRegisterButton}
          >
            Daftar konseling di sini!
          </button>
        </div>
      </div>

      {/*Kalmselor List Section*/}
      <div className="p-5 md:p-10 bg-cover bg-[url('/src/assets/cloud-background.jpeg')]">
        <p className="mb-5 text-4xl text-center font-bold">
          Berkenalan dengan Kalmselor of the month!
        </p>
        <div className="flex flex-wrap justify-center">
          <div className="my-5 md:m-5 md:w-3/4 lg:w-1/3">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xgP3o-roNDk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-screen md:w-full"
            ></iframe>
          </div>
          <div className="my-5 md:m-5 md:w-3/4 lg:w-1/3">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/cW-mc9XpawM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-screen md:w-full"
            ></iframe>
          </div>
          <div className="my-5 md:m-5 md:w-3/4 lg:w-1/3">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/9VlNgs5YrdU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-screen md:w-full"
            ></iframe>
          </div>
        </div>

        <div className="mb-2.5 mt-1 text-center">
          <button
            className="bg-white hover:bg-primaryBlue text-lg text-primaryBlue font-semibold hover:text-white py-2 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
            onClick={handleClickListKalmselorButton}
          >
            Lihat Kalmselor lain
          </button>
        </div>
      </div>

      {/*Article Section*/}
      <div className="flex flex-col md:flex-row-reverse justify-around items-center p-5 md:p-10 bg-primaryOrange">
        <div className="object-fit md:w-1/3">
          <img src={articleImage} alt="article" />
        </div>

        <div className="flex flex-col justify-center p-4 md:w-1/3 text-left">
          <p className="py-5 text-2xl md:text-3xl text-white font-bold">
            Baca artikel psikologi di sini!
          </p>
          <div className="flex justify-center md:justify-start py-10">
            <button
              className="bg-white hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              onClick={handleClickArticleButton}
            >
              Klik di sini
            </button>
          </div>
        </div>
      </div>

      {/*Testimony Section*/}
      <div className="flex flex-col p-5 md:p-10">
        <div className="flex flex-col justify-center items-center mb-5">
          <div className="pb-10">
            <p className="text-2xl md:text-4xl text-center">
              Mereka Yang Telah Terbantu oleh KALM
            </p>
          </div>
          <div className="flex flex-wrap justify-center items-center md:w-2/3">
            {businesses.map((business, index) => (
              <div
                key={index}
                className="flex justify-center items-center md:w-1/3 p-6"
              >
                <img src={business.image} alt="play-store" />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center py-10">
          <button
            className="bg-transparent hover:bg-primaryBlue text-lg text-primaryBlue font-semibold hover:text-white py-2 md:py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
            onClick={handleClickCollaborate}
          >
            Bekerja sama dengan kami
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
