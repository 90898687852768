import React, { useState } from 'react'
import config from '../config'

const defaultFormData = {
  firstName: '',
  lastName: '',
  email: '',
  message: ''
}

const ContactForm = () => {
  const [formData, setFormData] = useState(defaultFormData)

  const modifyFormData = (e: any, key: string) => {
    setFormData({
      ...formData,
      [key]: e.target.value
    })
  }

  return (
    <form action={`mailto:${config.contact.support}`} method="post">
      <div className="flex flex-row">
        <div className="flex justify-start w-full mb-5">
          <input type="text"
                 id="firstName"
                 className="block p-2.5 mr-2.5 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                 placeholder="First Name"
                 onChange={e => modifyFormData(e, 'firstName')}
                 required />
        </div>
        <div className="flex justify-end w-full mb-5">
          <input type="text"
                 id="lastName"
                 className="block p-2.5 ml-2.5 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                 onChange={e => modifyFormData(e, 'lastName')}
                 placeholder="Last Name" />
        </div>
      </div>
      <div className="mb-6">
        <input type="email"
               id="email"
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               onChange={e => modifyFormData(e, 'email')}
               placeholder="Email Address"
               required />
      </div>
      <textarea id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                onChange={e => modifyFormData(e, 'message')}
                placeholder="Pesan" />
      <button type="submit"
              className="mt-5 text-white bg-primaryBlue hover:bg-primaryBlue focus:ring-4 focus:outline-none focus:ring-primaryBlue font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
        Kirim
      </button>
    </form>
  )
}

export default ContactForm
