import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config";
import { fetchApiGolang, postApiGolang } from "../api";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  HeadingBurnedOut,
  HeadingMentalHealth,
  HeadingStress,
} from "../components/questionnaire/Heading";
import {
  AnswerType,
  FormValues,
  Option,
  Question,
} from "../types/Questionnaire";
import Loader from "../components/Loader";

const range4 = [1, 2, 3, 4];
const range5 = [1, 2, 3, 4, 5];

const Questionnaire = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [questions, setQuestions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const Heading = () => {
    switch (type) {
      case "STRESS": {
        return <HeadingStress />;
      }
      case "BURNED_OUT": {
        return <HeadingBurnedOut />;
      }
      default: {
        return <HeadingMentalHealth />;
      }
    }
  };

  const getQuestions = (type: string) => {
    setIsLoading(true);
    let params = [];
    params.push(`code=${type}`);
    const urlParams = params.length > 0 ? `${params.join("&")}` : "";
    fetchApiGolang(config.api.getQuestionnaire + "?" + urlParams).then(
      (response) => {
        const questions = response.items;
        setQuestions(questions);
        setIsLoading(false);
      }
    );
  };

  const { register, handleSubmit } = useForm<FormValues>();
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    postApiGolang(config.api.postQuestionnaire, data).then((response) => {
      navigate(`/quiz/questionnaireResult`, { state: response });
    });
  };

  useEffect(() => {
    if (type) {
      getQuestions(type);
    } else {
      return navigate("/quiz");
    }
  }, [type]);

  return (
    <div className="mx-auto min-h-[62vh] w-10/12 lg:w-3/4 text-primaryGrey">
      <Heading />
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full lg:w-10/12 flex-wrap mx-auto pb-5">
            <input type="hidden" {...register(`code`)} value={type} />
            {questions.map((entry: Question, questionIndex: number) => (
              <div
                key={entry.id}
                className={`rounded-lg my-5 w-full ${
                  (questionIndex + 1) % 2 === 1
                    ? `bg-halfOpacityOrange`
                    : `bg-halfOpacityYellow`
                } drop-shadow-lg shadow-md`}
              >
                <div className="md:m-5">
                  <div className="flex flex-col w-full px-5 py-4">
                    <div className="flex justify-start mb-2 lg:mb-4">
                      <input
                        type="hidden"
                        {...register(
                          `answers.${questionIndex}.question_id` as const
                        )}
                        value={entry.id}
                      />
                      <p className="mr-1">{questionIndex + 1 + ". "}</p>
                      <p>{entry.question}</p>
                    </div>
                    {/* render answer view for rate_4 answers */}
                    {entry.question_type === AnswerType.RATE_4 && (
                      <div className="flex flex-row px-5 py-2">
                        <span className="mr-3">{range4[0]}</span>
                        {range4.map((value, index) => (
                          <span key={index}>
                            <input
                              type="radio"
                              className="checked:bg-primaryOrange mr-3"
                              {...register(
                                `answers.${questionIndex}.answer_value` as const,
                                {
                                  valueAsNumber: true,
                                  required: true,
                                }
                              )}
                              value={value}
                            />
                          </span>
                        ))}
                        <span className="mr-3">
                          {range4[range4.length - 1]}
                        </span>
                      </div>
                    )}
                    {/* render answer view for rate_5 answers */}
                    {entry.question_type === AnswerType.RATE_5 && (
                      <div className="flex flex-row px-5 py-2">
                        <span className="mr-3">{range5[0]}</span>
                        {range5.map((value, index) => (
                          <span key={index}>
                            <input
                              type="radio"
                              className="checked:bg-primaryOrange mr-3"
                              {...register(
                                `answers.${questionIndex}.answer_value` as const,
                                {
                                  required: true,
                                }
                              )}
                              value={value}
                            />
                          </span>
                        ))}
                        <span className="mr-3">
                          {range5[range5.length - 1]}
                        </span>
                      </div>
                    )}
                    {/* render answer view for multiple choice answers */}
                    {entry.options.map((option: Option) => (
                      <div key={option.id} className="flex flex-row px-5 py-1">
                        <input
                          type="radio"
                          className="checked:bg-primaryOrange mr-3"
                          {...register(
                            `answers.${questionIndex}.answer_value` as const,
                            {
                              required: true,
                            }
                          )}
                          value={option.id}
                        />
                        <p>{option.question_value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex w-full py-10 items-center content-center justify-center">
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="w-1/2 text-center bg-transparent hover:bg-primaryBlue text-md md:text-lg text-primaryBlue font-semibold hover:text-white py-4 px-4 md:px-8 border border-primaryBlue hover:border-transparent rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Questionnaire;
