import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import logo from "../assets/logo.png";
import playStoreLogo from "../assets/play-store.png";
import appStoreLogo from "../assets/app-store.png";
import instagramLogo from "../assets/instagram.png";
import facebookLogo from "../assets/facebook.png";
import twitterLogo from "../assets/twitter.png";
import youtubeLogo from "../assets/youtube.png";

const Footer = () => {
  const navigate = useNavigate();

  const {
    facebook,
    instagram,
    youtube,
    twitter,
    playStore,
    appStore,
    collaborate,
  } = config.external;
  const { about, faq, contact, business, become } = config.pages;

  const handleClickFacebookIcon = () => (window.location.href = facebook);
  const handleClickInstagramIcon = () => (window.location.href = instagram);
  const handleClickYoutubeIcon = () => (window.location.href = youtube);
  const handleClickTwitterIcon = () => (window.location.href = twitter);
  const handleClickPlayStore = () => (window.location.href = playStore);
  const handleClickAppStore = () => (window.location.href = appStore);

  const handleClickAboutUs = () => navigate(about);
  const handleClickFaq = () => navigate(faq);
  const handleClickContactUs = () => navigate(contact);
  const handleClickBecomeKalmselor = () => navigate(become);
  const handleClickOurServices = () => navigate(business);
  const handleClickCollaborate = () => (window.location.href = collaborate);

  return (
    <div className="relative mx-auto text-primaryGrey">
      <footer className="flex flex-col md:p-20 md:pt-0 bg-gray-200">
        <div className="flex flex-col lg:flex-row justify-between pt-5 md:pt-0">
          <div className="flex flex-row lg:flex-col justify-between items-center px-11">
            <div className="md:px-4 py-0">
              <img
                src={logo}
                className="object-fit w-32 md:w-36 h-32 md:h-36"
                alt="logo"
              />
            </div>

            <div>
              <div className="md:px-4 py-0">
                <img
                  src={playStoreLogo}
                  className="object-contain w-24 md:w-36 h-10 md:h-12 cursor-pointer"
                  alt="play-store"
                  onClick={handleClickPlayStore}
                />
              </div>
              <div className="md:px-4 py-0">
                <img
                  src={appStoreLogo}
                  className="object-contain w-24 md:w-36 h-10 md:h-12 cursor-pointer"
                  alt="app-store"
                  onClick={handleClickAppStore}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center pt-8 text-xs md:text-lg px-10">
            <table className="table-auto">
              <thead>
                <tr className="text-left">
                  <th className="pb-5 px-4">Perusahaan</th>
                  <th className="pb-5 px-4 md:px-20">Bantuan</th>
                  <th className="pb-5 px-4">KALM for Business</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="py-1 px-4 cursor-pointer hover:text-primaryOrange"
                    onClick={handleClickAboutUs}
                  >
                    Tentang KALM
                  </td>
                  <td
                    className="py-1 px-4 md:px-20 cursor-pointer hover:text-primaryOrange"
                    onClick={handleClickFaq}
                  >
                    FAQ
                  </td>
                  <td
                    className="p-1 px-4 cursor-pointer hover:text-primaryOrange"
                    onClick={handleClickOurServices}
                  >
                    Layanan Kami
                  </td>
                </tr>
                <tr>
                  <td
                    className="p-1 px-4 cursor-pointer hover:text-primaryOrange"
                    onClick={handleClickBecomeKalmselor}
                  >
                    Kalmselor
                  </td>
                  <td
                    className="p-1 px-4 md:px-20 cursor-pointer hover:text-primaryOrange"
                    onClick={handleClickContactUs}
                  >
                    Kontak Kami
                  </td>
                  <td
                    className="p-1 px-4 cursor-pointer hover:text-primaryOrange"
                    onClick={handleClickCollaborate}
                  >
                    Kerja Sama
                  </td>
                </tr>
                <tr>
                  <td className="p-1 px-4 cursor-pointer hover:text-primaryOrange">
                    Blog
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr className="self-center m-4 md:m-10 w-full border-gray-700" />

        <div className="flex flex-row justify-between p-5 md:p-0 text-xs md:text-lg">
          <div className="flex md:pr-4 pt-5 justify-center items-center">
            <p>
              PT. Sanubari Senantiasa Sejahtera. <br /> Copyright 2018. <br />{" "}
              All rights reserved.
            </p>
          </div>

          <div className="flex justify-center items-center pt-5 pl-2">
            <p>Follow us:</p>
            <img
              src={facebookLogo}
              className="object-fit w-7 h-7 mx-2.5 cursor-pointer"
              alt="facebook"
              onClick={handleClickFacebookIcon}
            />
            <img
              src={instagramLogo}
              className="object-fit w-7 h-7 mx-2.5"
              alt="instagram"
              onClick={handleClickInstagramIcon}
            />
            <img
              src={youtubeLogo}
              className="object-fit w-8 h-8 mx-2.5"
              alt="youtube"
              onClick={handleClickYoutubeIcon}
            />
            <img
              src={twitterLogo}
              className="object-fit w-7 h-7 mx-2.5"
              alt="twitter"
              onClick={handleClickTwitterIcon}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer
